import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRequest } from "../../services/request";
import { useWallet } from "./WalletProvider";
import Password from "./WalletResetPass/Password";
import Phrase from "./WalletResetPass/Phrase";
import { encryptMessage } from "utilities/encrypt-aes-cbc";
import { Box, Type } from "ui";
import LayoutMasked from "layout/LayoutMasked";
import logo from "../../assets/logo-white.png";

function WalletResetPass() {
  const [words, setWords] = useState(new Array(12).fill(""));
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState("");

  const {
    state: { privateKey },
    dispatch,
  } = useWallet();

  const req = useRequest();
  const navigate = useNavigate();

  const handleChange = (e, i) => {
    const newWords = words.map((w, index) =>
      index === i ? e.target.value : w
    );
    setWords(newWords);
  };

  const handlePhrase = async () => {
    setError("");
    setLoading(true);
    try {
      const resData = await req(
        `wallets/`,
        { mnemonic: words.join(" ") },
        { method: "POST" },
        true
      );
      const currentPublicKey = window.localStorage.getItem("wallet_public_key");
      if (resData.public_key === currentPublicKey) {
        window.localStorage.setItem("wallet_public_key", resData.public_key);
        dispatch({ type: "set_private_key", payload: resData.private_key });
        setShowPass(true);
      } else setError("The phrase is not correct.");
    } catch (e) {
      console.log(e);
      if (e.status === 400) setError("The phrase is not correct.");
    } finally {
      setLoading(false);
    }
  };

  const handlePassword = async (password) => {
    console.log("password: ", password);
    const cipherPack = await encryptMessage(privateKey, password);
    const cipherPackBuffer = new Uint8Array(cipherPack);
    window.localStorage.setItem("wallet_enc_pack", `[${cipherPackBuffer}]`);
    navigate("/wallet");
  };

  return (
    <LayoutMasked id="reset-wallet">
      <Box center w={900} sx={{ ta: "center", p: 40 }}>
        <Box sx={{ p: 6, br: 2, bgc: "black", d: "inline-block", mb: 30 }}>
          <img
            src={logo}
            alt="stitchain"
            width={40}
            style={{ display: "block" }}
          />
        </Box>
        <Type var="h5" as="h5">
          Restore Wallet
        </Type>

        {!showPass ? (
          <Phrase
            words={words}
            onChange={handleChange}
            loading={loading}
            next={handlePhrase}
          />
        ) : (
          <Password next={handlePassword} loading={loading} />
        )}

        {Boolean(error.length) && (
          <Type sx={{ mxw: 260, mt: 30, mx: "auto", c: "red" }}>{error}</Type>
        )}
      </Box>
    </LayoutMasked>
  );
}

export default WalletResetPass;
