import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { useSchemas } from "../schemas/schemaProvider";

const Context = createContext();

export function useProducts() {
  return useContext(Context);
}

const initState = {
  list: [],
  product: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_product":
      return { ...state, product: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ProductProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const { fetchSchema } = useSchemas();

  const fetchList = useCallback(
    async (page = 0, pageSize = 10, keyword = null) => {
      dispatch({ type: "status", payload: "fetching" });
      let query = `page=${page + 1}&page_size=${pageSize}`;
      if (keyword) query += `&keyword=${keyword}`;
      const resData = await req(`products/?${query}`, null, {}, true);
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.results });
      dispatch({ type: "set_count", payload: resData.count });
    },
    [req]
  );

  const fetchProduct = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`products/${id}/`, null, {}, true);
        dispatch({ type: "set_product", payload: resData });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );

  const fetchFullProd = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        const data = await fetchProduct(id);

        dispatch({ type: "status", payload: `fetching` });
        const prodSchema = await fetchSchema(data["product_fields_schema"]);
        data.details = {};
        data.details.productSchema = prodSchema;
        const itemSchema = await fetchSchema(data["item_fields_schema"]);
        data.details.itemSchema = itemSchema;
        dispatch({ type: "status", payload: `idle` });
        dispatch({ type: "set_product", payload: data });

        resolve(data);
      });
    },
    [fetchProduct, fetchSchema]
  );

  const createProduct = useCallback(
    async (data, tag) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            "products/",
            data,
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: { ...resData, tag } });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const editProduct = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `products/${id}/`,
            data,
            { method: "PATCH" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const deleteProduct = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`products/${id}/`, null, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        fetchProduct,
        createProduct,
        editProduct,
        deleteProduct,
        fetchFullProd,
      }}
    >
      {children}
    </Context.Provider>
  );
}
