import { useAuth } from "../../services/auth";
import { useState } from "react";
import Account from "./profileTabs/AccountInfo";
import Wallet from "./profileTabs/WalletInfo";
import { Box, Grid, Type } from "ui";
import Layout from "layout/Layout";
import BrandInfo from "./profileTabs/BrandInfo";


function ProfileInfo() {
  const auth = useAuth();

  if (!auth.user) return;

  return (
    <Layout id="profile-info">
      <Type var="h4" as="h4" sx={{mb: 40}}>
        Your profile info on Stitchain services
      </Type>


        <Grid cols={["1fr 1fr 1fr", "1fr 1fr", "1fr", "1fr"]} gap="30" sx={{mxw: 1500}}>
          <Box>
            <Account />
          </Box>
          {/* <Box> */}
            <BrandInfo />
            <Wallet />
          {/* </Box> */}
        </Grid>

    </Layout>
  );
}

export default ProfileInfo;
