import { useState } from "react";
import Modal from "./Modal";

function Thumb({ thumb, img, w, alt }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <img
        src={thumb}
        width={w}
        alt={alt}
        onClick={() => setOpen(true)}
        style={{ cursor: "pointer" }}
      />
      <Modal onClose={handleClose} show={open}>
        <img src={img} alt="full width" onClick={() => setOpen(true)} />
      </Modal>
    </>
  );
}

export default Thumb;
