import styled from "styled-components";
import { useAuth } from "../../services/auth";
import { Box, Button, Icon, Link, Type } from "../../ui";
import Avatar from "../../ui/Avatar";
import Card from "../../ui/Card";
import Dropdown from "../../ui/core/Dropdown";
import useImageLink from "../../utilities/hooks/useImageLink";

const List = styled.ul`
  list-style: none;
  padding: 10px 20px;
  margin: 0;
  border-bottom: ${(p) => p.theme.color.gray200} 1px solid;
  li {
    margin: 10px 0;
    font-size: 15px;
  }
`;

function UserInfo(props) {
  const { user } = useAuth();

  return (
    <Box sx={{ fs: 13, c: "gray300" }} {...props}>
      <Box sx={{ m: 5 }}>
        <Type as="strong">
          {user?.tokens?.user?.firstName} {user?.tokens?.user?.lastName}
        </Type>
      </Box>
    </Box>
  );
}

function ProfileOpts() {
  const { signout } = useAuth();

  return (
    <Card sx={{ minw: 150, br: "0 0 5px 5px" }} padding="0">
      <UserInfo className="h-lg" sx={{ mb: 30 }} />

      <List>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/profile">Profile</Link>
        </li>
      </List>

      <Button
        // size="large"
        variant="text"
        iconName="powerOff"
        bg="secondary"
        sx={{ w: "calc(100% - 40px)", mx: 20, my:10 }}
        onClick={signout}
      >
        Sign out
      </Button>
    </Card>
  );
}

function UserAccount() {
  const { user } = useAuth();
  const imgUrl = useImageLink(user?.user?.avatarURL);

  return (
    <Dropdown space={21} content={<ProfileOpts />}>
      <Avatar
        role="button"
        aria-label="current user account"
        var="dark"
        className="avatar"
        size={26}
        sx={{ cursor: "pointer" }}
      >
        {imgUrl ? (
          <img src={imgUrl} alt="user profile avatar" />
        ) : (
          <Icon name="user" w={18} c="gray900" />
        )}
      </Avatar>
    </Dropdown>
  );
}

export default UserAccount;
