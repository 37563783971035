import { emptyStringToNull } from "utilities/yupSchemaCreator";

const fields = [
  {
    name: "name",
    type: "text",
    label: "Product Name",
    validationType: "string",
    required: true,
    validations: [
      {
        type: "required",
        params: ["this field is required."],
      },
    ],
  },
  {
    name: "tag",
    type: "number",
    resourceName: "tags",
    data: [],
    mapData: { value: "id", label: "name" },
    label: "Product Tag",
    validationType: "number",
    required: true,
    validations: [
      {
        type: "required",
        params: ["Required."],
      },
      {
        type: "nullable",
        params: [true],
      },
      {
        type: "transform",
        params: [emptyStringToNull],
      },
    ],
  },
  /*
  {
    name: "name",
    type: "number",
    label: "name",
    validationType: "number",
    required: true,
    validations: [
      {
        type: "required",
        params: ["this field is required."],
      },
    ],
  },
  */
];

export default fields;
