import { Link } from "react-router-dom";
import logo from "../../assets/logo-white.png";
import { Flex, Type } from "../../ui";

function Logo(props) {
  return (
    <Flex
      {...props}
      jc="start"
      gap={10}
    >
      <img src={logo} alt="Stitchain logo" style={{ width: 32 }} /> <Type sx={{c: 'white', fw: 500, fs: 18}}>Stitchain</Type>
    </Flex>
  );
}

export default Logo;
