import { useLocation, useNavigate } from "react-router-dom";
import LayoutMasked from "../../layout/LayoutMasked";
import LoginForm from "./LoginForm";
import { Box, Flex, Grid, Type } from "../../ui";
import Logo from "../../layout/sidebar/Logo";
import ForgotPassForm from "./ForgotPassForm";
import { useState } from "react";
import { useAutoAnimate } from '@formkit/auto-animate/react'

function Login() {
  const [login, setLogin] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [animationParent] = useAutoAnimate()

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/";

  const handleSuccess = () => {
    navigate(from, { replace: true });
  };

  return (
    <LayoutMasked>
      <Grid cols={["40% 60%", "40% 60%", "40% 60%", "auto"]} gap={0} sx={{ h: ["100%"] }}>
        <Box relative sx={{ bgc: "black", h: ["100%","100%" ,"100%" , 180]}}>
          <Logo sx={{ m: 40 }} />
          <Box as="blockquote" abs={{ b: 40, r: 40 }}>
            <Type as="div" sx={{ c: "gray400", fs: [14, 14, 14, 12], fw: 300, pl: 15, mb: [0, 0, 0, -25] }}>
              &mdash; A unique protocol to link real-world <br /> products and
              assets to the digital world.
            </Type>
          </Box>
        </Box>
        <Flex dir="column" ai="flex-start" gap={30} sx={{ m: [120, 120, 100, 30], w: [322, 322, 322, '100%'] }}>
          <Box sx={{mnw: 300}}>
            <Type var="h4" as="h4" sx={{ mb: 10 }}>
              {login ? "Login" : "Reset Password"}
            </Type>
            {Boolean(!login) && (
              <Type>
                Type your email below to receive intructions on how to reset
                your stitchain account password.
              </Type>
            )}
          </Box>
          <div ref={animationParent}>
            {login ? (
              <LoginForm onSuccess={handleSuccess} setLogin={setLogin} />
            ) : (
              <ForgotPassForm setLogin={setLogin} />
            )}
          </div>
        </Flex>
      </Grid>
    </LayoutMasked>
  );
}

export default Login;
