import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Icon, Pagination, TableContainer, Type } from "ui";
import Layout from "layout/Layout";
import { useTags } from "./provider";

const cols = [
  { label: "ID", name: "id" },
  { label: "Name", name: "name" },
  {
    label: "Bound",
    name: "bound",
    props: {
      style: {
        textAlign: "center",
      },
    },
    render: (row) =>
      row.bound ? (
        <Box sx={{ ta: "center" }}>
          <Icon name="check" c="black" w={16} />
        </Box>
      ) : (
        ""
      ),
  },
  {
    label: "Stitchain verified",
    name: "stit_based_verification",
    props: {
      style: {
        textAlign: "center",
      },
    },
    render: (row) =>
      row.stit_based_verification ? (
        <Box sx={{ ta: "center" }}>
          <Icon name="check" c="black" w={16} />
        </Box>
      ) : (
        <Box sx={{ ta: "center" }}>
          -
        </Box>
      ),
  },
  {
    label: "W Verified Hash",
    name: "write_verification_hash",
    props: {
      style: {
        textAlign: "center",
      },
    },
    render: (row) =>
      row.write_verification_hash ? (
        <Box sx={{ ta: "center" }}>
          <Icon name="check" c="black" w={16} />
        </Box>
      ) : (
        <Box sx={{ ta: "center" }}>
          -
        </Box>
      ),
  },
];

function List() {
  const {
    state: { list, status, count, page, perPage },
    dispatch,
    fetchList,
  } = useTags();

  useEffect(() => {
    fetchList(page, perPage);
  }, [fetchList, page, perPage]);

  const handleChangePage = (event, newPage) =>
    dispatch({ type: "set_page", payload: newPage });

  return (
    <Layout>
      <Flex jc="space-between" sx={{ mb: 60 }}>
        <Type var="h3" as="h2" sx={{ mt: 0, mb: 0 }}>
          Tags List
        </Type>

        {/* <Access path="/products/new">
          <Button onClick={handleAdd} iconName="plus">
            New Product
          </Button>
        </Access> */}
      </Flex>

      <Box>
        {/* <Box sx={{ ml: "auto", w: 240 }}>
          <SearchInput setTerm={setTerm} />
        </Box> */}

        <TableContainer
          empty={list?.length === 0}
          data={list}
          status={status}
          aria-label="Tags table"
          cols={cols}
        />

        <Pagination
          sx={{ mt: 20 }}
          data-testid="tags-pagination"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={perPage}
        />
      </Box>
    </Layout>
  );
}

export default memo(List);
