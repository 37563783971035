import { memo, useEffect, useRef, useState } from "react";
import { useFields } from "./fieldProvider";
import { Box, Button, Flex, Icon, Pagination, TableContainer, Type } from "ui";
import { useSchemas } from "views/schemas/schemaProvider";
import { Access } from "services/auth";
import Modal from "ui/Modal";
import FieldForm from "./FieldForm";

const fieldType = (type) => {
  switch (type) {
    case 1:
      return <Type>Number</Type>;
    case 2:
      return <Type>Text</Type>;
    default:
      return <Type>Yes/No</Type>;
  }
};

const cols = [
  { label: "ID", name: "id" },
  { label: "Name", name: "name" },
  {
    label: "Type",
    name: "field_type",
    render: (item) => fieldType(item.field_type),
  },
  {
    label: "Is Required",
    name: "is_required",
    render: (item) =>
      item.is_required ? <Type>True</Type> : <Type>False</Type>,
    options: { align: "center" },
  },
];

function Fields({ handleEdit }) {
  const inputEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(null);
  const [field, setField] = useState(null);
  const [page, setPage] = useState(0);

  const {
    state: { list: schemas, selectedId },
  } = useSchemas();

  const schema = schemas.find((l) => l.id === selectedId);

  const {
    state: { list, status, count },
    fetchList,
    deleteField,
  } = useFields();

  useEffect(() => {
    fetchList(page);
  }, [fetchList, page]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setEdit(null);
      setField({
        id: null,
        name: '',
        field_type: "",
        is_required: false
      });
    }, 100);
  };

  const onSuccess = () => {
    handleClose();
  };

  const handleDelete = deleteField;

  const openEdit = (field) => {
    setEdit(field.id);
    setField(field);
    handleOpen();
  };

  const handleChangePage = (newPage) => setPage(newPage);

  return (
    <Box>
      <Box sx={{ bgc: "white", br: 4, of: "hidden", mb: 15 }}>
        <Flex jc="space-between" sx={{ py: 20, px: 30 }}>
          <Flex jc="flex-start" gap={10}>
            <Type var="h5" as="h4">
              {schema.name}
            </Type>
            <Access path="/schemas/edit">
              <Icon
                disabled={schema.locked}
                name="pen"
                hover
                w={15}
                onClick={() => handleEdit(schema)}
              />
            </Access>
          </Flex>
          <Button
            onClick={handleOpen}
            iconName="plus"
            disabled={schema.locked}
            variant="outlined"
          >
            New Fields
          </Button>
        </Flex>

        <Modal show={open}>
          <Box
            abs={{ t: 10, r: 10 }}
            sx={{ z: 999, cursor: "pointer" }}
            onClick={handleClose}
          >
            <Icon hover name="xmark" w={14} c="black" />
          </Box>
          <FieldForm
            {...{
              inputEl,
              field,
              edit,
              onSuccess,
            }}
          />
        </Modal>

        <TableContainer
          empty={list?.length === 0}
          status={status}
          aria-label="Schema fields table"
          cols={cols}
          data={list}
          tableProps={{
            sx: { br: 0 },
          }}
          moreOptions={[
            {
              name: "edit",
              render: (row) => (
                <Icon
                  disabled={schema.locked}
                  hover
                  name="pen"
                  w={14}
                  onClick={() => openEdit(row)}
                />
              ),
            },
            {
              name: "delete",
              render: (row) => (
                <Icon
                  hover
                  disabled={schema.locked}
                  name="trash"
                  w={14}
                  onClick={() => {
                    if (window.confirm("Are you sure?")) handleDelete(row.id);
                  }}
                />
              ),
            },
          ]}
        />
      </Box>

      <Pagination
        data-testid="fields-pagination"
        component="div"
        count={count}
        page={page}
        info={true}
        pager={false}
        onPageChange={handleChangePage}
      />
    </Box>
  );
}

export default memo(Fields);
