import styled from "styled-components";
import { Box, Icon } from "ui";

const Toggle = styled(Box)`
  cursor: pointer;
  padding: 10px 6px;
  width: 18px;
`;

function ToggleSidebar({isOpen, ...props}) {
  return (
    <Toggle {...props}>
      <div className="bars" />
      <Icon name={isOpen ? "arrowLeft" : "arrowRight"} c="white" w="16" />
    </Toggle>
  );
}

export default ToggleSidebar;
