import { memo } from "react";
import SchemaRowActions from "./SchemaRowActions";
import { Flex, Type } from "ui";

function SchemaRow({
  schema,
  status,
  selectedId,
  handleToggle,
  handleDelete,
  handleClone,
}) {
  if (!schema) return "loading";
  const labelId = `checkbox-list-label-${schema.id}`;
  const isSelected = schema.id === selectedId;

  return (
    <Flex
      role="tab"
      jc="space-between"
      onClick={handleToggle(schema.id)}
      aria-selected={isSelected ? "true" : "false"}
      sx={{
        px: 15,
        py: 10,
        cursor: !isSelected ? "pointer" : 'default',
        bgc: isSelected ? "gray300" : "white",
        ":hover": {
          bgc: !isSelected ? "gray100"  : "gray300",
        },
      }}
    >
      <Type sx={{ fw: 500 }} id={labelId}>
        {schema.name}
      </Type>
      <SchemaRowActions {...{ schema, status, handleDelete, handleClone }} />
    </Flex>
  );
}

export default memo(SchemaRow);
