import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import Profile from "./Profile";
import ProfileInfo from "./ProfileInfo";
import Email from "./Email";
import Name from "./Name";
import Username from "./Username";
import Password from "./Password";

export default (
  <Route
    path="profile"
    element={
      <RequireAuth>
        <Profile />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ProfileInfo />
        </RequireAuth>
      }
    />
    <Route
      path="name"
      element={
        <RequireAuth>
          <Name />
        </RequireAuth>
      }
    />
    <Route
      path="email"
      element={
        <RequireAuth>
          <Email />
        </RequireAuth>
      }
    />
    <Route
      path="username"
      element={
        <RequireAuth>
          <Username />
        </RequireAuth>
      }
    />
    <Route
      path="password"
      element={
        <RequireAuth>
          <Password />
        </RequireAuth>
      }
    />
  </Route>
);
