import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProducts } from "./productProvider";
import { Access } from "../../services/auth";
import { Box, Button, Flex, Icon, Pagination, TableContainer, Type } from "ui";
import SearchInput from "components/SearchInput";
import Layout from "layout/Layout";

const cols = [
  { label: "ID", name: "id" },
  { label: "Name", name: "name" },
  { label: "Tag", name: "tag.name" },
  { label: "Manufacturer", name: "manufacturer.name" },
];

function List() {
  const [term, setTerm] = useState();
  const navigate = useNavigate();

  const {
    state: { list, status, count, page, perPage },
    dispatch,
    fetchList,
    deleteProduct,
  } = useProducts();

  useEffect(() => {
    fetchList(page, perPage, term);
  }, [fetchList, page, perPage, term]);

  const handleDelete = (id) => {
    deleteProduct(id);
  };

  const handleShow = (item) => {
    navigate(`/products/${item.id}`);
  };

  const handleEdit = (item) => {
    navigate(`/products/edit/${item.id}`);
  };

  const handleAdd = () => navigate("new");

  const handleChangePage = (event, newPage) =>
    dispatch({ type: "set_page", payload: newPage });

  return (
    <Layout>
      <Flex jc="space-between" sx={{ mb: 60 }}>
        <Type var="h3" as="h2" sx={{ mt: 0, mb: 0 }}>
          Products List
        </Type>

        <Access path="/products/new">
          <Button onClick={handleAdd} iconName="plus">
            New Product
          </Button>
        </Access>
      </Flex>

      <Box>
        <Box sx={{ ml: "auto", w: 240 }}>
          <SearchInput setTerm={setTerm} />
        </Box>

        <TableContainer
          empty={list?.length === 0}
          data={list}
          status={status}
          aria-label="Product table"
          cols={cols}
          moreOptions={[
            {
              name: "show",
              render: (row) => (
                <Icon hover name="eye" w={14} onClick={() => handleShow(row)} />
              ),
            },
            {
              name: "edit",
              render: (row) => (
                <Icon hover name="pen" w={14} onClick={() => handleEdit(row)} />
              ),
            },
            {
              name: "delete",
              render: ({ id }) => (
                <Icon
                  hover
                  name="trash"
                  w={14}
                  onClick={() => {
                    if (window.confirm("Are you sure?")) handleDelete(id);
                  }}
                />
              ),
            },
          ]}
        />

        <Pagination
          sx={{ mt: 20 }}
          data-testid="product-pagination"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={perPage}
        />
      </Box>
    </Layout>
  );
}

export default memo(List);
