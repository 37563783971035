import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import Wallet from "./Wallet";
// import WalletResetPass from "./WalletResetPass";
import WalletShow from "./WalletShow";
// import WalletUnlock from "./WalletUnlock";
import WalletImport from "./WalletImport";
import WalletUnlock from "./WalletUnlock";
import WalletResetPass from "./WalletResetPass";

export default (
  <Route
    path="wallet"
    element={
      <RequireAuth>
        <Wallet />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <WalletShow />
        </RequireAuth>
      }
    />
    <Route
      path="unlock"
      element={
        <RequireAuth>
          <WalletUnlock />
        </RequireAuth>
      }
    />
    <Route
      path="import"
      element={
        <RequireAuth>
          <WalletImport />
        </RequireAuth>
      }
    />
    <Route
      path="reset-password"
      element={
        <RequireAuth>
          <WalletResetPass />
        </RequireAuth>
      }
    />
  </Route>
);
