import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useProducts } from "./productProvider";
import ProductDetails from "./ProductDetails";
import ProductItems from "../items/List";
import { Access } from "../../services/auth";
import { Flex, Grid, Link, Type } from "ui";
import Layout from "layout/Layout";
import { useFields } from "views/fields/fieldProvider";
import { mapProductDetails } from "./functions";

function Product() {
  const [productList, setProductList] = useState([]);
  let { id } = useParams();
  id = Number(id);

  const {
    state: { product },
    dispatch,
    fetchFullProd,
  } = useProducts();

  const {
    state: { list: productSchemaFields },
    fetchList,
  } = useFields();

  useEffect(() => {
    if (!id) return;
    fetchFullProd(id);
    return () => dispatch({ type: "set_product", payload: null });
  }, [fetchFullProd, id, dispatch]);

  useEffect(() => {
    if (!product || !product["json_data"]) return;
    fetchList(0, 100, product["product_fields_schema"]);
  }, [fetchList, product]);

  useEffect(() => {
    if(!product || !productSchemaFields?.length) return;
    setProductList(mapProductDetails(product, productSchemaFields));
  }, [product, productSchemaFields]);

  return (
    <Layout id="product-details">
      <Flex jc="flex-start" aria-label="breadcrumb" gap={5}>
        <Link to="/">Home</Link>
        <Type>-</Type>
        <Link to="/products">Products</Link>
      </Flex>
      <Type var="h3" as="h3" sx={{ mt: 18, mb: 60 }}>
        Product Details
      </Type>

      <Grid cols="2fr 5fr" gap={30} sx={{ mt: 20 }}>
        {productList && <ProductDetails productList={productList} />}

        <Access path="/items">{<ProductItems />}</Access>
      </Grid>
    </Layout>
  );
}

export default Product;
