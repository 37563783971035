import { memo } from "react";
import { Box, Flex, Icon, Loading, Tooltip } from "ui";
import Dropdown from "ui/core/Dropdown";

function SchemaRowActions({ schema, status, handleClone, handleDelete }) {
  if (!schema) return "loading";
  return (
    <Flex gap={12}>
      <Dropdown click={false} content={<Tooltip>Clone</Tooltip>}>
        {status.split(" ")[0] === "cloning" &&
        Number(status.split(" ")[1]) === schema.id ? (
          <Loading size={15} />
        ) : (
          <Icon
            c="gray600"
            hover
            w={16}
            name="copy"
            onClick={() => handleClone(schema.id)}
            aria-label="clone spec"
          />
        )}
      </Dropdown>
      {schema.locked ? (
        <Dropdown click={false} content={<Tooltip>Locked</Tooltip>}>
          <Icon
            hover
            c="gray500"
            w={14}
            name="lock"
            aria-label="locked spec"
          />
        </Dropdown>
      ) : (
        <Dropdown click={false} content={<Tooltip>Delete</Tooltip>}>
          {status.split(" ")[0] === "deleting" &&
          Number(status.split(" ")[1]) === schema.id ? (
            <Loading size={15} />
          ) : (
            <Icon
              hover
              name="trash"
              c="red"
              w={14}
              onClick={() => handleDelete(schema.id)}
              aria-label="delete spec"
            />
          )}
        </Dropdown>
      )}
    </Flex>
  );
}

export default memo(SchemaRowActions);
