import { useEffect, useRef, useState } from "react";
import { useRequest } from "services/request";
import AnalyticEcommerce from "./components/AnalyticEcommerce";
// import IncomeAreaChart from "./components/IncomeAreaChart";
import MonthlyBarChart from "./components/MonthlyBarChart";
import OrdersTable from "./components/OrdersTable";
import {
  Box,
  Card,
  Divider,
  Flex,
  Grid,
  Loading,
  Select,
  Type,
} from "../../ui";
import Layout from "layout/Layout";
import { getUserCountry, percentageChange } from "utilities/functions";
import { useSearchParams } from "react-router-dom";
import MapChart from "./components/MapChart";
import { useAuth } from "services/auth";
// import ReportAreaChart from "./components/ReportAreaChart";
// import useStats from "./views/stats/statsProvider"

const Dashboard = () => {
  const [iProfile, setIProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const auth = useAuth();

  const [days, setDays] = useState(365);
  const [searchParams, setSearchParams] = useSearchParams();
  const fake = searchParams.get("demo-data");

  const [totals, setTotals] = useState({});
  const [mintedItems, setMintedItems] = useState([]);
  const req = useRequest();

  useEffect(() => {
    console.log(`Country: ${getUserCountry().country}`);
    console.log(`City: ${getUserCountry().city}`);
    console.log(`Region: ${getUserCountry().region}`);
    console.log(`Timezone: ${getUserCountry().timezone}`);
  }, []);

  useEffect(() => {
    (async function () {
      const data = await auth.getIdentity();
      setIProfile(data);
      setLoading(false);
    })();
  }, [auth]);

  useEffect(() => {
    (async () => {
      const resData = await req(
        `stats/totals/?days_ago=${days}`,
        null,
        {},
        true
      );
      setTotals(
        fake
          ? {
              products: {
                current_range: 50,
                previous_range: 20,
              },
              items: {
                current_range: 500,
                previous_range: 750,
              },
              redeemed: {
                current_range: 30,
                previous_range: 34,
              },
              verification_transactions: {
                current_range: 100,
                previous_range: 80,
              },
            }
          : resData
      );
    })();
    // fetchTotals()
  }, [req, fake, days]);

  useEffect(() => {
    (async () => {
      const resData2 = await req(`stats/minted_items/`, null, {}, true);
      setMintedItems(resData2);
    })();
    // fetchTotals()
  }, [req]);

  return (
    <Layout>
      {!loading ? (
        <img
          width={160}
          src={`${auth.user.user.configs.ipfs_base_url}/${iProfile.image}`}
          alt=""
        />
      ) : (
        <Loading size={18} />
      )}

      {/* <Type>{getUserCountry().country} / {getUserCountry().city} / {getUserCountry().region}  / {getUserCountry().timzone}</Type> */}

      <Flex jc="space-between">
        <Type
          sx={{
            lh: 20,
            mt: 40,
            fw: 600,
            fs: 17,
            border: ["gray600", 3, "solid", 0, "b"],
            c: "gray1k",
            mb: -1,
            p: 10,
          }}
        >
          Quick Statistics
        </Type>
        <Select
          onChange={(data) => {
            switch (data.value) {
              case "7 Days":
                setDays(7);
                break;
              case "1 Month":
                setDays(30);
                break;
              case "6 Months":
                setDays(180);
                break;
              case "1 Year":
                setDays(365);
                break;
              default:
                setDays(30);
            }
          }}
          search={false}
          defaultValue="1 Year"
          size="small"
          noSeperator
          style={{ width: 140, marginBottom: -10 }}
          data={["7 Days", "1 Month", "6 Months", "1 Year"]}
        />
      </Flex>

      <Divider border="gray300" h={1} />

      <Grid
        cols={["1fr 1px 1fr 1px 1fr 1px 1fr", "1fr "]}
        gap={25}
        sx={{ w: "100%", mt: 25 }}
      >
        <AnalyticEcommerce
          title="Total Products"
          count={totals.products?.current_range}
          percentage={percentageChange(
            totals.products?.previous_range,
            totals.products?.current_range
          )}
          color="info"
        />
        <Divider vertical border="gray400" />
        <AnalyticEcommerce
          title="Total Items"
          count={totals.items?.current_range}
          percentage={percentageChange(
            totals.items?.previous_range,
            totals.items?.current_range
          )}
          color="info"
        />
        <Divider vertical border="gray400" />
        <AnalyticEcommerce
          title="Total Redeemed"
          count={totals.redeemed?.current_range}
          percentage={percentageChange(
            totals.redeemed?.previous_range,
            totals.redeemed?.current_range
          )}
        />
        <Divider vertical border="gray400" />
        <AnalyticEcommerce
          title="Total Transactions"
          count={totals.verification_transactions?.current_range}
          percentage={percentageChange(
            totals.verification_transactions?.previous_range,
            totals.verification_transactions?.current_range
          )}
        />
      </Grid>

      <Divider border="gray300" h={50} />

      <Box sx={{ mb: 10 }}>
        <Type var="h3" as="h3" sx={{ mb: 20, mt: 40 }}>
          Top Transactions Locations
        </Type>
        <MapChart />
      </Box>

      <Divider border="gray300" h={50} />

      <Grid
        cols={["100%", "100%"]}
        sx={{ mt: 40, w: ["auto", "auto", "auto", "75vw"] }}
        gap={40}
      >
        <Box>
          <Type var="h3" as="h3" sx={{ mb: 20 }}>
            Minted Items
          </Type>

          <Card elevation={0} bg="transparent" padding="0">
            <Box
              relative
              sx={{
                p: 30,
                bgc: "gray200",
                fl: "right",
                d: "inline-block",
                mb: -20,
                mt: -50,
                z: 100,
              }}
            >
              <Type var="h2" sx={{ ta: "right" }}>
                {fake
                  ? Math.floor(Math.random() * 500)
                  : mintedItems.reduce(
                      (partialSum, a) => partialSum + a.count,
                      0
                    )}
              </Type>
              <Type sx={{ fw: 600, ta: "right" }}>Total Items</Type>
            </Box>
            {Boolean(mintedItems.length) && (
              <MonthlyBarChart
                labels={mintedItems.map((item) => item.date)}
                data={mintedItems.map((item) => {
                  if (fake) return Math.floor(Math.random() * 100);
                  return item.count;
                })}
              />
            )}
          </Card>
        </Box>
        <Box>
          <Type var="h3" as="h3" sx={{ mb: 20 }}>
            Recent Orders
          </Type>

          <OrdersTable />
        </Box>
      </Grid>
    </Layout>
  );
};

export default Dashboard;
