import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ItemForm from "./ItemForm";
import { useUtils } from "../../services/utilsProvider";
import { useProducts } from "../products/productProvider";
import Layout from "layout/Layout";
import { Flex, Link, Loading, Type } from "ui";
import { useFields } from "views/fields/fieldProvider";

function AddItem() {
  const [itemSchemaFields, setItemSchemaFields] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const { notify } = useUtils();

  const {
    state: { product },
    fetchProduct,
  } = useProducts();

  const {
    fetchList: fetchFields,
  } = useFields();

  useEffect(() => {
    if (!params.pid) return;
    fetchProduct(params.pid);
  }, [fetchProduct, params.pid]);

  useEffect(() => {
    if (!product || !product["item_fields_schema"]) return;
    const schemaId = product["item_fields_schema"];
    (async () => {
      const data = await fetchFields(0, 100,schemaId);
      setItemSchemaFields(data);
    })();
  }, [product, fetchFields]);

  const handleSuccess = () => {
    navigate(-1);
    notify("Item saved successfully");
  };

  return (
    <Layout id="item-add">
      <Flex jc="flex-start" aria-label="breadcrumb" gap={5}>
        <Link to="/">Home</Link>
        <Type>-</Type>
        <Link to="/products">Products</Link>
        <Type>-</Type>
        <Link to={`/products/${product?.id}`}>Product: {product?.name}</Link>
      </Flex>
      <Type var="h3" as="h3" sx={{ mt: 18 }}>
        Add Item
      </Type>

      {!product || !itemSchemaFields ? (
        <Loading />
      ) : (
        <ItemForm
          product={product}
          schemaFields={itemSchemaFields}
          handleSuccess={handleSuccess}
        />
      )}
    </Layout>
  );
}

export default memo(AddItem);
