var apiUrl;

if (process.env.REACT_APP_ENV === "production")
  apiUrl = "https://api.stitchain.com/v1";
else if (process.env.REACT_APP_ENV === "sandbox")
  apiUrl = "https://sandbox.api.stitchain.com/v1";
else {
  apiUrl = "https://staging.api.stitchain.com/v1";
}

const GOOGLE_APT_KEY = "AIzaSyCerC9R94lDgwBKR1yf_EAqL0oa1CRdW8c";

const access = {
  1001: [/\/manufacturers$/i],
  1002: [/\/manufacturers\/edit/i],
  1003: [/\/schemas$/i],
  1004: ["/schemas/new", /\/schemas\/edit/i],
  1005: [/\/users$/i],
  1006: ["/users/new", /\/users\/edit/i],
  2001: [/\/products$/i],
  2002: ["/products/new", /\/products\/edit/i],
  2003: [/\/items$/i],
  2004: [
    /\/products\/\d+\/items\/new/i,
    "/items/new",
    "/items/edit",
    /\/products\/\d+\/items\/\d+\/edit/i,
  ],
  2005: ["Bind With Nfc"],
};

export { apiUrl, access, GOOGLE_APT_KEY };
