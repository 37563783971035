import styled, { css } from "styled-components";
import stylesBlocks from "./core/stylesBuilder";
import Icon from "./Icon";
import Loading from "./Loading";

const StyledButton = styled.button.attrs((p) => ({
  bg: p.bg ? p.theme.color[p.bg] : p.theme.color.primary,
  fg: p.bg ? p.theme.color[p.bg+'Fg'] : p.theme.color.primaryFg, // forground color for text
  color: p.theme.color.gray50,
  size: p.size || 'medium',
}))`
  padding: 0 16px;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: ${p => p.hasIcon ? "space-between" : "center"};
  gap: 6px;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  min-width: 100px;
  svg {
    line-height: 0;
    margin-left: 0px;
    transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  }
  ${(p) => {
    if (p.variant === "outlined")
      return css`
        background-color: transparent;
        color: ${(p) => p.bg};
        border: ${(p) => p.bg} 1px solid;
        svg {
          fill: ${(p) => p.bg};
        }
        &:hover:not(:disabled),
        &.selected {
          background-color: ${(p) => p.bg};
          color: ${(p) => p.fg};
          border-color: ${(p) => p.bg};
          svg {
            fill: ${(p) => p.fg};
          }
        }
      `;
    else if (p.variant === "text")
      return css`
        background-color: transparent;
        color: ${(p) => p.bg};
        border-radius: 0;
        border: ${(p) => p.theme.color.bg}22 0 solid;
        padding: 0 8px;
        &:hover {
          background-color: ${(p) => p.bg}11;
        }
        svg {
          fill: ${(p) => p.bg};
        }
      `;
    else
      return css`
        background-color: ${(props) => props.bg};
        color: ${(props) => props.color};
        border: none;
        svg {
          fill: ${(p) => p.theme.color.white};
        }
      `;
  }}
  &:hover {
    opacity: 0.8;
    transform: scale(0.99);
  }
  &:active,
  &:disabled {
    opacity: 0.6;
    transform: scale(0.975);
  }
  &:disabled {
    cursor: default;
  }
  ${(props) => {
    if (props.size === "large")
      return css`
        height: 48px;
        font-size: 15px;
        border-width: 3px;
        svg {
          width: 13px;
          margin-left: 4px;
        }
      `;
    else if (props.size === "medium")
      return css`
        height: 38px;
        /* padding: 0 32px; */
        font-size: 14px;
        font-weight: 500;
        svg {
          width: 13px;
          margin-left: 2px;
        }
      `;
    else if (props.size === "small")
      return css`
        height: 30px;
        /* padding: 0 24px; */
        font-size: 11px;
        svg {
          width: 13px;
          margin-left: 0;
        }
      `;
  }}
  // depricated class use size="small" props instead
  &.small {
    height: 40px;
    font-size: 15px;
    text-transform: none;
  }
  // depricated class use size="large" props instead
  &.large {
    height: 54px;
    font-size: 21px;
    text-transform: none;
    padding: 0 26px;
  }
  span.loading {
    margin-top: 0px;
  }
  // depricated class use variant="text" prop instead
  &.transparent {
    background-color: white;
    color: ${(props) => props.theme.color.grayLight};
    box-shadow: 0px 4px 8px #0000001f;
  }
  // depricated class use variant="outlined" prop instead
  &.outlined {
    background-color: transparent;
    color: ${(props) => props.theme.color.grayLight};
    border: ${(props) => props.theme.color.dark} 1px solid;
  }
  ${stylesBlocks}
`;

function Button({
  children,
  loading = false,
  disabled,
  iconName = null,
  ...props
}) {
  return (
    <StyledButton hasIcon={iconName} disabled={loading || disabled} {...props}>
      {children}
      {loading ? <Loading size={20} /> : <>{iconName && <Icon name={iconName} c="white" w={14} />}</>}
    </StyledButton>
  );
}

export default Button;
