import { memo, useEffect } from "react";
import { Box, Button, Card, Icon, InputText, Type } from "ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useUtils } from "services/utilsProvider";
import { useSchemas } from "./schemaProvider";

const YupSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

function SchemaDialogForm({ schema, edit, onSuccess, inputEl }) {
  const { createSchema } = useSchemas();
  const { notify } = useUtils();

  const {
    register,
    handleSubmit,
    reset,
    // setValue,
    // getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(YupSchema),
    defaultValues: schema,
  });

  useEffect(() => {
    reset(schema);
  }, [schema, reset]);

  const onSubmit = async (values) => {
    try {
      await createSchema(values["name"], edit);
      reset();
      notify(`Spec ${edit ? "updated" : "added"} successfully`);
      onSuccess();
    } catch (e) {
      console.log(e);
      notify(e.errors["non_field_errors"].join(", "), "alert");
    }
  };

  return (
    <Card as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box sx={{ ta: "center", py: 30, minw: 300 }}>
        <Icon name="schema" w={18} />
        <Type var="h6" as="h5" sx={{ mt: 10 }}>
          {edit ? `Editing Spec "${schema.name}"` : "Create Spec"}
        </Type>
      </Box>
      <InputText
        inputRef={inputEl}
        id="name"
        label="Spec name"
        error={!!errors.name}
        msg={errors.name?.message}
        inputProps={{
          ...register("name"),
          autoFocus: true,
          required: true,
        }}
      />

      <Button
        sx={{ w: "100%", ai: "center", mt: 20 }}
        // onClick={handleSubmit}
        loading={isSubmitting}
      >
        {edit ? "Save" : "Create"}
      </Button>
    </Card>
  );
}

export default memo(SchemaDialogForm);
