import styled, { css } from "styled-components";
import Type from "./Type";

const Wrap = styled.div`
  margin: 25px 0;
  input {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    & + label {
      display: block;
      position: relative;
      padding: 0 0 0 40px;
      line-height: 22px;
      /* font-size: 15px; */
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
      ${(props) => {
        switch (props.size) {
          case "small":
            return css`
              font-size: 14px;
            `;
          case "large":
            return css`
              font-size: 16px;
            `;
          default:
            return css`
              font-size: 15px;
            `;
        }
      }};
      &::before {
        content: "";
        position: relative;
        display: inline-block;
        margin-right: 10px;
        margin-left: -40px;
        ${(props) => {
          switch (props.size) {
            case "small":
              return css`
                width: 16px;
                height: 16px;
              `;
            case "large":
              return css`
                width: 20px;
                height: 20px;
              `;
            default:
              return css`
                width: 18px;
                height: 18px;
              `;
          }
        }};
        top: 4px;
        background: transparent;
        border: #bebebe 1px solid;
        border-radius: 3px;
      }
    }
    &:checked + label::before {
      background: ${(p) => p.theme.color.secondary};
      border: ${(p) => p.theme.color.secondary} 1px solid;
    }
    &:checked + label::after {
      content: "";
      position: absolute;
      top: 10px;
      left: 4px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      ${(props) => {
        switch (props.size) {
          case "small":
            return css`
              height: 3px;
              width: 9px;
            `;
          case "large":
            return css`
              height: 5px;
              width: 11px;
            `;
          default:
            return css`
              height: 4px;
              width: 9px;
            `;
        }
      }};
      transform: rotate(-45deg);
    }
    &:focus + label::before {
      outline: ${(p) => p.theme.color.secondary} solid 1px;
      box-shadow: 0 0px 8px ${(props) => props.theme.color.secondary};
    }
    &:disabled + label {
      color: #575757;
    }
    &:disabled + label::before {
      background: #ddd;
    }
  }
`;

function Checkbox({
  id = "",
  label,
  msg,
  error,
  size = "normal",
  inputProps,
  ...props
}) {
  return (
    <Wrap {...props} size={size} className={`${error ? "error" : ""}`}>
      <input type="checkbox" id={id} {...inputProps} />
      <label htmlFor={id}>{label}</label>
      {msg && <Type sx={{ mt: 8, c: "red" }}>{msg}</Type>}
    </Wrap>
  );
}

export default Checkbox;
