import Layout from "layout/Layout";
import VerificationTransactionCard from "./components/VerificationTransactionCard";
import { useEffect } from "react";
import { useStats } from "views/stats/statsProvider";
import { Box, Type } from "ui";

function VerificationTransaction() {
  const {
    state: { verificationTransaction },
    fetchVerificationTransaction,
  } = useStats();

  useEffect(() => {
    fetchVerificationTransaction();
  }, [fetchVerificationTransaction]);

  return (
    <Layout>
      <Type var="h3" as="h3">
        Verification Transactions
      </Type>

      {/* <Type>Hey, here’s what been happning.</Type> */}
      {verificationTransaction.map((item) => (
        <Box key={item.id} sx={{ my: 40 }}>
          <VerificationTransactionCard data={item} />
        </Box>
      ))}
    </Layout>
  );
}

export default VerificationTransaction;
