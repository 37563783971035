import { memo } from "react";
import styled, { css } from "styled-components";
import { Box, Button, Flex, Icon, Loading } from "../ui";
import Label from "../ui/Label";

const Wrap = styled.div`
  .preview {
    img {
      width: 100%;
      margin: 0;
      display: block;
    }
  }
  ${(props) =>
    props.document &&
    css`
      .wrap-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
      }
    `}
`;

const FileLabel = styled.label`
  .custom-file-input {
    visibility: hidden;
    max-width: 0;
  }
  &.doc-preview {
    background-color: white;
    position: relative;
    .close {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        fill: #ff4a55;
      }
    }
    .icon {
      cursor: pointer;
      padding: 18px;
      background-color: #f6eeff;
      border-radius: 10px;
      svg {
        width: 18px;
      }
    }
  }
`;

function FileField({
  label,
  preview,
  setPreview,
  inputProps,
  msg = null,
  error = false,
  buttonLabel,
  document,
  documentFile,
  disabled = false,
  readOnly,
  edit,
  onClose,
  loading,
  ...props
}) {
  return (
    <Wrap document={document} preview={preview} {...props}>
      <Label>{label}</Label>

      {Boolean(!document && preview) && (
        <Box
          padding="0"
          relative
          sx={{
            mnh: 180,
            bgc: "gray100",
            bgsize: "cover",
            mb: 20,
            of: "hidden",
            br: 4,
          }}
          className="preview"
        >
          {/* {Boolean(preview) && ( */}
          <img src={preview} alt="preview uploaded file" />
          {/* )} */}
          {loading && (
            <Flex abs={{ t: 0, l: 0 }} sx={{ w: "100%", h: "100%" }}>
              <Loading />
            </Flex>
          )}
        </Box>
      )}

      <div className="wrap-grid">
        {!disabled && (
          <Flex jc="flex-start">
            <FileLabel
              jc="flex-start"
              document={document}
              htmlFor={inputProps?.name + "file"}
              edit={edit}
            >
              <Button as="span" type="button" size="small" sx={{text: 'uppercase'}}>{buttonLabel}</Button>
              <input
                className="custom-file-input"
                id={inputProps?.name + "file"}
                type="file"
                {...inputProps}
              />
            </FileLabel>
            {Boolean(preview) && <Button variant="text" sx={{text: 'uppercase'}} onClick={() => setPreview(null)}>Cancel</Button>}
          </Flex>
        )}
        {documentFile && (
          <FileLabel document={document} className="doc-preview">
            {!readOnly && (
              <span className="close" onClick={onClose}>
                <Icon name="circleXMark" />
              </span>
            )}
            <a
              className="icon"
              href={documentFile.url}
              download={documentFile.url}
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="document" />
            </a>
            <a
              className="info"
              href={documentFile.url}
              download={documentFile.url}
              target="_blank"
              rel="noreferrer"
            >
              <p>{documentFile.name}</p>
              {documentFile.size ? (
                <small>{documentFile.size} KB</small>
              ) : (
                <small>Click to download</small>
              )}
            </a>
          </FileLabel>
        )}
      </div>
    </Wrap>
  );
}

export default memo(FileField);
