import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { useSchemas } from "../schemas/schemaProvider";

const Context = createContext();

export function useFields() {
  return useContext(Context);
}

const initState = {
  list: [],
  count: 0,
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };

    default:
      throw new Error("Invalid dispatch type");
  }
};

export default function FieldProvider({ children }) {
  const {
    state: { selectedId },
  } = useSchemas();
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    (page, pageSize=10, schemaId) => {
      return new Promise(async (resolve) => {
        const id = schemaId || selectedId;
        const query = `page=${page + 1}&page_size=${pageSize}`;
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(
          `fields-schemas/${id}/data-fields/?${query}`,
          null,
          {},
          true
        );
        dispatch({ type: "status", payload: `idle` });
        dispatch({ type: "set_list", payload: resData.results });
        dispatch({ type: "set_count", payload: resData.count });
        resolve(resData.results);
      });
    },
    [selectedId, req]
  );

  const createField = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `fields-schemas/${selectedId}/data-fields/`,
            data,
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [selectedId, req]
  );

  const editField = useCallback(
    (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `editing ${id}` });
        try {
          const resData = await req(
            `data-field/${id}/`,
            data,
            { method: "PATCH" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: "idle" });
        }
      });
    },
    [req]
  );

  const deleteField = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`data-field/${id}/`, null, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        createField,
        editField,
        deleteField,
      }}
    >
      {children}
    </Context.Provider>
  );
}
