import { memo, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductForm from "./ProductForm";
import { useUtils } from "../../services/utilsProvider";
import { Card, Flex, Loading, Type } from "ui";
import Layout from "layout/Layout";
import { useFields } from "views/fields/fieldProvider";
import { useProducts } from "./productProvider";

function EditProduct() {
  const params = useParams();
  const navigate = useNavigate();
  const { notify } = useUtils();

  const {
    state: { product },
    fetchProduct,
  } = useProducts();
  const {
    state: { list: productSchemaFields },
    fetchList: fetchProductSchemaFields,
  } = useFields();

  useEffect(() => {
    fetchProduct(params.id);
  }, [params, fetchProduct]);

  useEffect(() => {
    if (!product) return;
    fetchProductSchemaFields(0, 100, product.product_fields_schema);
  }, [product, fetchProductSchemaFields]);

  const handleSuccess = () => {
    navigate(-1);
    notify("Product saved successfully");
  };

  return (
    <Layout id="product-details">
      <Flex jc="flex-start" aria-label="breadcrumb" gap={5}>
        <Link to="/">Home</Link>
        <Type>-</Type>
        <Link to="/products">Products</Link>
      </Flex>
      <Type var="h3" as="h3" sx={{ mt: 18 }}>
        Edit Product
      </Type>

      <Card sx={{ mt: 40, w: 360 }}>
        {Boolean(productSchemaFields && product) ? (
          <ProductForm
            product={product}
            productSchemaFields={productSchemaFields}
            handleSuccess={handleSuccess}
          />
        ) : (
          <Loading />
        )}
      </Card>
    </Layout>
  );
}

export default memo(EditProduct);
