import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../../services/auth";
import { useState } from "react";
import { useUtils } from "services/utilsProvider";
import { Box, Button, Card, Flex, Icon, InputText, Link, Type } from "ui";
import Layout from "layout/Layout";

function Username() {
  const [serverError, setServerError] = useState(null);

  const navigate = useNavigate();
  const { notify } = useUtils();
  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { username: auth.user.user.username },
  });

  const onSubmit = async (values) => {
    try {
      await auth.editAccount(values);
      notify("Username has been saved successfully")
      navigate("/profile");
    } catch (error) {
      console.log("error", error);
      setServerError(error?.errors?.username);
    }
  };

  return (
    <Layout id="edit-username">
      <Type var="h4">
        <Link to={-1}>
          <Icon name="arrowLeft" w={14} c="black" />
        </Link>{" "}
        Username
      </Type>
      <Type sx={{ py: 15 }}>
        Changes to your username will be reflected across your Stitshain
        Account.
      </Type>

      <Card sx={{ mxw: 400, mt: 40 }}>
        <Type var="h5">Change Username</Type>

        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 30 }}
        >
          {serverError && (
            <Type sx={{ mt: 20, mb: 15, c: "red" }}>{serverError}</Type>
          )}

          <InputText
            id="username"
            label="Username"
            name="username"
            inputProps={{
              autoFocus: true,
              ...register("username"),
            }}
          />

          <Flex jc="flex-start" sx={{ mt: 30 }}>
            <Button loading={isSubmitting}>Save</Button>
            <Button type="button" variant="text" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Flex>
        </Box>
      </Card>
    </Layout>
  );
}

export default Username;
