import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import Products from "./Products";
// import Product from "./Product";
import List from "./List";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import Product from "./Product";
import AddItem from "views/items/AddItem";
import EditItem from "views/items/EditItem";
// import AddProduct from "./AddProduct";
// import EditProduct from "./EditProduct";
// import AddItem from "../items/AddItem";
// import EditItem from "../items/EditItem";

export default (
  <Route
    path="products"
    element={
      <RequireAuth>
        <Products />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <List />
        </RequireAuth>
      }
    />
    <Route
      path=":id"
      element={
        <RequireAuth>
          <Product />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddProduct />
        </RequireAuth>
      }
    />
     <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditProduct />
        </RequireAuth>
      }
    />
    <Route
      path=":pid/items/new"
      element={
        <RequireAuth>
          <AddItem />
        </RequireAuth>
      }
    />
    <Route
      path=":pid/items/:id/edit/"
      element={
        <RequireAuth>
          <EditItem />
        </RequireAuth>
      }
    />
  </Route>
);
