import { emptyStringToNull } from "./yupSchemaCreator";

function mapType(type) {
  switch (type) {
    case 1:
      return "number";
    case 2:
      return "string";
    default:
      return "bool";
  }
}

function genFieldsObjFromSchema(schema) {
  return schema.map((f) => {
    const type = mapType(f.field_type);
    const obj = {
      name: f.id.toString(),
      type: type === "bool" ? "checkbox" : type,
      label: f.name,
      validationType: type,
      required: f.is_required,
      validations: [
        {
          type: f.is_required,
          params: ["this field is required."],
        },
      ],
    };

    if (f.is_required) {
      obj.validations.push({
        type: "required",
        params: ["this field is required."],
      });
      if (type === "bool")
        obj.validations.push({
          type: "oneOf",
          params: [[true], "Field must be checked"],
        });
    }

    if (type === "number")
      obj.validations.push(
        {
          type: "transform",
          params: [emptyStringToNull],
        },
        {
          type: "nullable",
          params: [true],
        }
      );

    return obj;
  });
}

export { mapType, genFieldsObjFromSchema };
