import UserAccount from "./UserAccount";
import { Flex, Link } from "../../ui";
import Logo from "layout/sidebar/Logo";

function Header({ setOpen, ...props }) {
  return (
    <Flex
      as="header"
      jc="space-between"
      gap={[20, 20, 20, 0]}
      {...props}
      sx={{ gc: "1 / 3", px: 20, py: 8, bgc: "black" }}
    >
      <Logo as={Link} to="/" sx={{ my: 10 }} />
      <UserAccount />
    </Flex>
  );
}

export default Header;
