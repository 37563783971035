function mapProductDetails(productDetails, productSchemaFields) {
  const tempsFields = productSchemaFields.map((f) => ({
    name: f.name,
    value: productDetails?.["json_data"]?.[f.id],
  }));
  tempsFields.unshift(
    {
      name: "Product name",
      value: productDetails.name,
    },
    {
      name: "Product tag",
      value: productDetails.tag?.name,
    },
    {
      name: "Manufacturer",
      value: productDetails.manufacturer?.name,
    },
    {
      name: "Product Spec",
      value: productDetails.details?.productSchema.name,
    },
    {
      name: "Item Spec",
      value: productDetails.details?.itemSchema.name,
    },
  );

  return tempsFields;
}

export { mapProductDetails };
