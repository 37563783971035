import React, { useEffect, useRef, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import topomap from "data/topomap.json";
import theme from "theme";
import { Box, Divider, Flex, Grid, Icon, Loading, Tooltip, Type } from "ui";
import styled from "styled-components";
import Dropdown from "ui/core/Dropdown";
import { useStats } from "views/stats/statsProvider";
import SideInfo from "./mapChart/SideInfo";

// const geoUrl =
//   "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json"

const colors = ["#666", "#ffc542", "#ff974a", "#0062ff"];

const SBox = styled(Box)`
  max-height: 520px;
  margin-left: 20px;
  svg {
    transform: scaleY(0.7);
    margin-top: -115px;
    path {
      transition: fill 0.1s ease-in-out;
      /* fill: ${(p) => p.theme.color.gray400}; */
      &:hover {
        fill: ${(p) => p.theme.color.gray500};
      }
      outline: none;
    }
  }
  @media screen and (max-width: 2000px) {
    max-height: auto;
    svg {
      margin-top: -80px;
    }
  }

  @media screen and (max-width: 1500px) {
    max-height: auto;
    svg {
      margin-top: -40px;
    }
  }
`;

export default function MapChart() {
  const [total, setTotal] = useState(0);
  const [highest, setHighest] = useState(0);
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });
  const mapRef = useRef();
  const boxRef = useRef();

  const {
    state: { transactionsLocations, status },
    fetchTransactionsLocations,
  } = useStats();

  useEffect(() => {
    fetchTransactionsLocations();
  }, [fetchTransactionsLocations]);

  useEffect(() => {
    if (transactionsLocations.length) {
      const sum = transactionsLocations.reduce(
        (acc, cur) => acc + cur.count,
        0
      );
      setTotal(sum);
      setHighest(Math.max(...transactionsLocations.filter(item => item.country !== null).map((item) => item.count)));
    }
  }, [transactionsLocations]);

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(position) {
    setPosition(position);
  }

  if (!transactionsLocations.length && status === 'fetching') return <Loading />;
  if(!transactionsLocations.length) return <Type>No data available.</Type>

  return (
    <Grid cols={["20% 1px 65% 15%"]} sx={{ mt: 40 }}>
      <SideInfo {...{ total, highest, colors }} />
      <Divider vertical border="gray400" />
      <SBox ref={boxRef}>
        <ComposableMap viewBox="30 60 800 420">
          <ZoomableGroup
            zoom={position.zoom}
            center={position.coordinates}
            onMoveEnd={handleMoveEnd}
            translateExtent={[
              [0, 0],
              [900, 600],
            ]}
          >
            <Geographies geography={topomap} ref={mapRef}>
              {({ geographies }) =>
                geographies.map((geo, i) => {
                  // console.log(geo);
                  const stats = transactionsLocations.filter(item => item.country !== null).find(
                    (item) => item.country === geo.properties.name
                  ) || { count: 0 };

                  let fill = theme.color.gray400;

                  if (stats.count < highest / 9) {
                    fill = colors[0];
                  } else if (stats.count < highest / 6) {
                    fill = colors[1];
                  } else if (stats.count < highest / 3) {
                    fill = colors[2];
                  } else if (stats.count >= highest / 3) {
                    fill = colors[3];
                  }

                  if (stats.count === 0) {
                    fill = theme.color.gray400;
                  }

                  return (
                    <Dropdown
                      key={i}
                      space={0}
                      click={false}
                      content={
                        <Tooltip>
                          <Type as="span" sx={{ c: "white", fw: "700", pr: 5 }}>
                            {geo.properties.name}:{" "}
                          </Type>
                          <Type as="span" sx={{ c: "white", ta: "center" }}>
                            {stats?.count || 0}
                          </Type>
                        </Tooltip>
                      }
                    >
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        strokeWidth={0.2}
                        stroke={theme.color.white}
                        fill={fill}
                      />
                    </Dropdown>
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      </SBox>
      <Flex dir="column" gap={15} ai="flex-start" jc="flex-start" sx={{ ta: "right", ml: 40 }}>
        <Icon
          onClick={handleZoomIn}
          name="plus"
          hover="gray700"
          circle
          w={16}
          c="gray400"
        />

        <Icon
          onClick={handleZoomOut}
          name="minus"
          hover="gray700"
          circle
          w={16}
          c="gray400"
        />
      </Flex>
    </Grid>
  );
}
