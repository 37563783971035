import { debounce } from "lodash";

import { InputText } from "ui";

function SearchInput({ setTerm }) {
  const handleChange = (e) => {
    let value = e.target.value;
    if (value.length < 3) value = null;
    setTerm(value);
  };

  const debouncedHandleChange = debounce(handleChange, 500);

  return (
    <InputText
      iconLeft="magnifyingGlass"
      placeholder="Search…"
      size="medium"
      inputProps={{ "aria-label": "search" }}
      onChange={debouncedHandleChange}
    />
  );
}

export default SearchInput;
