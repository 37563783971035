import { Box, Card, Flex, Icon, List, Type } from "ui";

function ProductDetails({ productList }) {

  return (
    <Box>
      <Type var="h5" as="h5" sx={{mb: 40}}>
        General Information
      </Type>
      <Card>
        <List>
          {productList.map((item, i) => (
            <Flex key={item.name + i} as="li" jc="space-between">
              <Type sx={{pr: 30, mnw: 180}}>{item.name}</Type>
              <Type>{typeof item.value === 'boolean' ? <Icon name="check" c="black" w={14} /> : item.value}</Type>
            </Flex>
          ))}
        </List>
      </Card>
    </Box>
  );
}

export default ProductDetails;
