import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUsers } from "./usersProvider";
import Layout from "layout/Layout";
import {
  Box,
  Button,
  Flex,
  Icon,
  Loading,
  Pagination,
  TableContainer,
  Type,
} from "ui";
import { Access } from "services/auth";
import Dropdown from "ui/core/Dropdown";
import RolesList from "./RolesList";

const Roles = (props) => {
  const {
    state: { status },
    edit,
  } = useUsers();

  const handleRolesChange = (roles) => {
    edit(props.user_id, { roles });
  };

  return (
    <Flex jc="flex-start" gap={20}>
      <Box>
        {props.roles.map((r) => (
          <Type key={r.name} sx={{ fw: "bold", fs: 13 }}>
            {r.name}
          </Type>
        ))}
      </Box>
      <Dropdown
        content={
          <span>
            <RolesList
              userRolesIds={props.roles.map((r) => r.id)}
              onChange={handleRolesChange}
            />
          </span>
        }
      >
        <span>
          {status === `creating-${props.user_id}` ? (
            <Loading />
          ) : (
            <Icon name="plus" c="black" w={12} hover />
          )}
        </span>
      </Dropdown>
    </Flex>
  );
};

const cols = [
  { label: "Email", name: "email" },
  {
    label: "Name",
    name: "full name",
    render: (row) => (
      <Type>
        {row.first_name} {row.last_name}
      </Type>
    ),
  },
  {
    label: "Roles",
    name: "roles",
    render: Roles,
  },
];

function UsersList() {
  const navigate = useNavigate();
  const {
    state: { list, status, count, page },
    dispatch,
    fetchList,
    remove,
  } = useUsers();

  useEffect(() => {
    fetchList(page);
  }, [fetchList, page]);

  const handleDelete = (id) => {
    remove(id);
  };

  const handleChangePage = (newPage) =>
    dispatch({ type: "set_page", payload: newPage });

  const handleAdd = () => navigate("new");

  return (
    <Layout id="product-list">
      <Flex jc="space-between" sx={{ mb: 60 }}>
        <Type var="h3" as="h2" sx={{ mt: 0, mb: 0 }}>
          Users List
        </Type>

        <Access path="/products/new">
          <Button onClick={handleAdd} iconName="plus">
            New User
          </Button>
        </Access>
      </Flex>

      <Box>
        <TableContainer
          empty={list?.length === 0}
          status={status}
          aria-label="User table"
          cols={cols}
          data={list}
          moreOptions={[
            {
              name: "delete",
              render: ({ user_id }) =>
                status === `deleting ${user_id}` ? (
                  <Loading />
                ) : (
                  <Icon
                    hover
                    name="trash"
                    w={14}
                    onClick={() => {
                      if (window.confirm("Are you sure?"))
                        handleDelete(user_id);
                    }}
                  />
                ),
            },
          ]}
        />

        <Pagination
          sx={{ mt: 20 }}
          count={count}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
    </Layout>
  );
}

export default memo(UsersList);
