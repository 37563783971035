import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useAuth } from "../../services/auth";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useWallet() {
  return useContext(Context);
}

const initState = {
  privateKey: null,
  publicKey: null,
  hasApproved: null,
  status: "idle",
  financialInfo: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_public_key":
      return { ...state, publicKey: action.payload };
    case "set_private_key":
      return { ...state, privateKey: action.payload };
    case "set_financial_info":
      return { ...state, financialInfo: action.payload };
    case "set_has_approved":
      return { ...state, hasApproved: action.payload };
    case "status":
      return { ...state, status: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function WalletProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const auth = useAuth();
  const req = useRequest();

  useEffect(() => {
    dispatch({
      type: "set_public_key",
      payload:
        auth.user?.manufacturer?.identity_address ||
        window.localStorage.getItem("wallet_public_key"),
    });
  }, [auth.user]);

  const getFinancialInfo = useCallback(() => {
    return new Promise(async (resolve) => {
      const user = JSON.parse(localStorage.getItem('user'))
      if (!user.user.manufacturer.identity_address) return resolve();
      dispatch({ type: "status", payload: `fetching` });
      const resData = await req(
        `wallets/${user.user.manufacturer.identity_address}/financial-info/`,
        null,
        {},
        true
      );
      dispatch({ type: "set_financial_info", payload: resData });
      dispatch({ type: "status", payload: `idle` });
      resolve(resData);
    });
  }, [req, state.publicKey]);

  const fetchHasApproved = useCallback(() => {
    return new Promise(async (resolve) => {
      dispatch({ type: "status", payload: `fetching` });
      const resData = await req(
        `identities/${state.publicKey}/is-approved/`,
        null,
        {},
        true
      );
      console.log("resData", resData);
      dispatch({ type: "set_has_approved", payload: resData.approved });
      dispatch({ type: "status", payload: `idle` });
      resolve(resData);
    });
  }, [req, state.publicKey]);

  return (
    <Context.Provider
      value={{ state, getFinancialInfo, fetchHasApproved, dispatch }}
    >
      {children}
    </Context.Provider>
  );
}
