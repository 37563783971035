import { Box, Button, Grid, InputText, Type } from "ui";

function Phrase({ words, onChange, next, loading }) {
  return (
    <>
      <Type sx={{ mxw: 400, mx: "auto", mb: 20 }}>
        Type your 12-Word Phrase
      </Type>
      <Box sx={{ mxw: 860, my: 40, mx: "auto", p: 40 }}>
        <Grid
          cols={[
            "repeat(6, 1fr)",
            "repeat(4, 1fr)",
            "repeat(3, 1fr)",
            "repeat(2, 1fr)",
          ]}
          rowGap={20}
          sx={{ p: 20, my: 0 }}
        >
          {words.map((w, i) => (
            <section key={i}>
              <Box sx={{ p: 4, m: 4 }}>
                <InputText
                  sx={{ mb: 0 }}
                  value={words[i]}
                  inputProps={{
                    onChange: (e) => onChange(e, i),
                  }}
                />
              </Box>
              <Box sx={{ c: "gray600", fs: 13 }}>{i + 1}</Box>
            </section>
          ))}
        </Grid>
      </Box>

      <Button sx={{ mt: 20 }} loading={loading} onClick={next}>
        Continue
      </Button>
    </>
  );
}

export default Phrase;
