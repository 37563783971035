import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useSchemas() {
  return useContext(Context);
}

const initState = {
  list: [],
  selectedId: null,
  schema: null,
  count: 0,
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_selected":
      return { ...state, selectedId: action.payload };
    case "reset_selected":
      var selectedId = null;
      if (state.list.length) selectedId = state.list[0].id;
      return { ...state, selectedId };
    case "set_list":
      return { ...state, list: [...action.payload] };
    // case "set_schema":
    //   return { ...state, schema: {...action.payload} };
    // case "clear_schema":
    //     return { ...state, schema: null };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };

    default:
      throw new Error("Invalid dispatch type");
  }
};

export default function SchemaProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (page = 0, pageSize = 10) => {
      dispatch({ type: "status", payload: `fetching` });
      const query = `page=${page + 1}&page_size=${pageSize}`;
      const resData = await req(`fields-schemas/?${query}`, null, {}, true);
      dispatch({ type: "set_list", payload: resData.results });
      dispatch({ type: "set_count", payload: resData.count });
      dispatch({ type: "reset_selected" });
      dispatch({ type: "status", payload: `idle` });
    },
    [req]
  );

  const fetchSchema = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`fields-schemas/${id}/`, null, {}, true);
        // dispatch({ type: "set_schema", payload: resData });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );

  const createSchema = useCallback(
    async (name, edit = false) => {
      dispatch({ type: "status", payload: `creating` });
      const pathend = edit ? `${state.selectedId}/` : "";
      const method = edit ? "PATCH" : "POST";
      const resData = await req(
        `fields-schemas/${pathend}`,
        { name },
        { method },
        true
      );
      dispatch({ type: edit ? "edit" : "add", payload: resData });
      dispatch({ type: "set_selected", payload: resData.id });
      dispatch({ type: "status", payload: `idle` });
    },
    [state.selectedId, req]
  );

  const deleteSchema = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`fields-schemas/${id}/`, null, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
      if (!state.selectedId || state.selectedId === id)
        dispatch({ type: "reset_selected" });
    },
    [state.selectedId, req]
  );

  const cloneSchema = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `cloning ${id}` });
      const resData = await req(
        `fields-schemas/${id}/clone/`,
        null,
        { method: "POST" },
        true
      );
      dispatch({ type: "add", payload: resData });
      dispatch({ type: "reset_selected" });
      dispatch({ type: "status", payload: "idle" });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        fetchSchema,
        createSchema,
        deleteSchema,
        cloneSchema,
      }}
    >
      {children}
    </Context.Provider>
  );
}
