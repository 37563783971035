import { memo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useUsers } from "./usersProvider";
import { useNavigate } from "react-router-dom";
import { Button, Card, InputText, Type } from "ui";
import RolesList from "./RolesList";

const schema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
});

function UserForm({ handleSuccess }) {
  const [error, setError] = useState(null);
  const [roles, setRoles] = useState([]);

  const navigate = useNavigate();

  const { create } = useUsers();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      const resData = await create({ ...values, roles });
      reset();
      handleSuccess(resData.user_id);
      return false;
    } catch (e) {
      if (e.errors)
        setError(
          <>
            <div>Server error.</div>
            {e.errors?.data?.map((e) => (
              <p>{e}</p>
            ))}
          </>
        );
      console.log("e", e);
    }
  };

  return (
    <Card
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 10, mxw: 500 }}
    >
      {error && <Type sx={{ mt: 20, mb: 20, c: "red" }}>{error}</Type>}

      <InputText
        label="Email"
        name="email"
        type="email"
        error={!!errors.email}
        msg={errors.email?.message}
        inputProps={{
          required: true,
          ...register("email"),
          autoFocus: true,
        }}
      />

      <InputText
        label="First name"
        name="first_name"
        error={!!errors.first_name}
        msg={errors.first_name?.message}
        inputProps={{
          required: true,
          ...register("first_name"),
        }}
      />

      <InputText
        label="Last name"
        name="last_name"
        error={!!errors.last_name}
        msg={errors.last_name?.message}
        inputProps={{
          required: true,
          ...register("last_name"),
        }}
      />

      <RolesList userRolesIds={roles} onChange={setRoles} />

      <Button sx={{ mt: 50, mr: 10 }} loading={isSubmitting}>
        Create
      </Button>
      <Button
        variant="text"
        sx={{ mt: 20, mb: 20 }}
        onClick={() => navigate(-1)}
      >
        Cancel
      </Button>
    </Card>
  );
}

export default memo(UserForm);
