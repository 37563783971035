import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useUsers() {
  return useContext(Context);
}

const initState = {
  list: [],
  selected: null,
  roles: [],
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
};

const id = "user_id";

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_roles":
      return { ...state, roles: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      console.log(action.payload)
      const newList = [...state.list, { ...action.payload }];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.user_id === action.payload.id ? { ...p, roles: action.payload.roles } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p[id] !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function UsersProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (page = 0, pageSize = 10) => {
      dispatch({ type: "status", payload: "fetching" });
      const query = `page=${page + 1}&page_size=${pageSize}`;
      const resData = await req(`stitchain-users/?${query}`, null, {}, true);
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.results });
      dispatch({ type: "set_count", payload: resData.count });
    },
    [req]
  );

  const fetchRoles = useCallback(
    async () => {
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(`roles/`, null, {}, true);
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_roles", payload: resData });
    },
    [req]
  );

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`stitchain-users/${id}/`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            "stitchain-users/",
            data,
            { method: "POST" },
            true
          );
          data.roles = data.roles.map(rid => state.roles.find(r => r.id === rid));
          dispatch({ type: "add", payload: {user_id: resData.user_id, ...data} });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req, state.roles]
  );

  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating-${id}` });
        try {
          await req(
            `stitchain-users/${id}/`,
            data,
            { method: "PATCH" },
            true
          );
          const roles = data.roles.map(rid => state.roles.find(r => r.id === rid));
          dispatch({ type: "edit", payload: {id, roles} });
          resolve(roles);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req, state.roles]
  );

  const remove = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`stitchain-users/${id}/`, null, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        fetchOne,
        create,
        edit,
        remove,
        fetchRoles,
      }}
    >
      {children}
    </Context.Provider>
  );
}
