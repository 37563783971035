import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useItems } from "./ItemProvider";
import { useProducts } from "../products/productProvider";
import { BoundFilter, NftFilter } from "./filters";
import { Access, useAuth } from "../../services/auth";
import { Box, Button, Flex, Icon, Pagination, TableContainer, Type } from "ui";
import SearchInput from "components/SearchInput";
import Thumb from "ui/Thumb";
import { turncate } from "utilities/functions";

function List() {
  const [term, setTerm] = useState();
  const [bound, setBound] = useState("all");
  const [nft, setNft] = useState("all");

  const navigate = useNavigate();

  const { user } = useAuth();

  const {
    state: { list, status, page, perPage, count },
    changePage,
    fetchList,
    deleteItem,
  } = useItems();

  const {
    state: { product },
  } = useProducts();

  const cols = [
    { label: "ID", name: "id" },
    { label: "Name", name: "name" },
    { label: "Description", name: "description", fn: turncate },
    {
      label: "Image",
      name: "image",
      render: (row) =>
        row.image && (
          <Thumb
            thumb={`${user.user.configs.ipfs_base_url}/${row.thumbnail}`}
            img={`${user.user.configs.ipfs_base_url}/${row.image}`}
            w="80"
            alt={`item ${row.name} photo`}
          />
        ),
    },
    {
      label: "Bound",
      name: "bound",
      filter: <BoundFilter filter={bound} setFilter={setBound} />,
      filtered: bound !== "all",
      props: {
        style: {
          textAlign: "center",
        }
      },
      render: (row) => row.bound ? <Box sx={{ta: 'center'}}>
        <Icon name="check" c="black" w={16} />
      </Box> : ""
    },
    {
      label: "NFT",
      name: "nft",
      filter: <NftFilter filter={nft} setFilter={setNft} />,
      filtered: nft !== "all",
      options: {
        align: "right",
      },
    },
  ];

  useEffect(() => {
    if (!product) return;
    fetchList(product.id, page, perPage, term, bound, nft);
  }, [fetchList, product, page, perPage, term, bound, nft]);

  const handleDelete = (id) => {
    deleteItem(id);
  };

  const handleEdit = (row) => {
    navigate(`/products/${product.id}/items/${row.id}/edit`);
  };

  return (
    <Box>
      <Flex jc="space-between" sx={{ mb: 20 }}>
        <Type var="h5" as="h5" sx={{ mt: 0, mb: 0 }}>
          Product Items
        </Type>

        <Access path="/items/new">
          <Button iconName="plus" onClick={() => navigate("items/new")}>
            New Item
          </Button>
        </Access>
      </Flex>

      <Box sx={{ ml: "auto", w: 240 }}>
        <SearchInput setTerm={setTerm} />
      </Box>

      <TableContainer
        empty={list?.length === 0}
        status={status}
        cols={cols}
        data={list}
        moreOptions={[
          {
            name: "edit",
            render: (row) => (
              <Icon hover name="pen" w={14} onClick={() => handleEdit(row)} />
            ),
          },
          {
            name: "delete",
            render: (row) => (
              <Icon
                hover
                name="trash"
                w={14}
                onClick={() => {
                  if (window.confirm("Are you sure?")) handleDelete(row.id);
                }}
              />
            ),
          },
        ]}
      />

      <Pagination
        sx={{ mt: 20 }}
        count={count}
        page={page}
        onPageChange={changePage}
        rowsPerPage={perPage}
      />
    </Box>
  );
}

export default List;
