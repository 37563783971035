import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, InputText, Link, Type } from "../../ui";
import { useUtils } from "../../services/utilsProvider";
import { useAuth } from "../../services/auth";

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required."),
});

function ForgotPassForm({ setLogin }) {
  const { notify } = useUtils();
  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      await auth.forgotPass(values);

      notify(
        "An email with instructions on how to change password has been sent."
      );
    } catch (error) {
      console.log("error", error);
      notify(
        "An error has occured please try again.", 'alert'
      );
    }
  };

  return (
    <Box as="form" sx={{ w: 322 }} onSubmit={handleSubmit(onSubmit)} noValidate>
      <InputText
        placeholder="Email Address"
        error={!!errors.email}
        msg={errors.email?.message}
        inputProps={{
          ...register("email"),
          autoFocus: true,
        }}
      />
      <Button
        type="submit"
        loading={isSubmitting}
        size="large"
        iconName="arrowRight"
        sx={{ mx: "auto", w: "100%" }}
      >
        Submit
      </Button>
      <Type as={Link} onClick={() => setLogin(true)} sx={{ ta: "center", mt: 40, fw: 500, d: "block" }}>
        Back
      </Type>
    </Box>
  );
}

export default ForgotPassForm;
