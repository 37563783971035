import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useUtils } from "../../services/utilsProvider";
import { useRequest } from "../../services/request";
import { useWallet } from "./WalletProvider";
import { Box, Button, InputText, Type } from "ui";
import { blob2bin } from "utilities/functions";
import ImageUpload from "./walletIdentity/ImageUpload";
import { useAuth } from "services/auth";

const FILE_SIZE = 2000000;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/webp",
];

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  brand: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  image: Yup.mixed()
    .required("Image is required")
    .test(
      "fileSize",
      "File too large, maximum 2 mb",
      (value) => null || (value && value[0]?.size <= FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => null || (value && SUPPORTED_FORMATS.includes(value[0]?.type))
    ),
});

const displayErrors = (errors) =>
  errors?.map((error, i) => <span key={i}>{error}</span>);

function WalletIdentity({ next, setPassword }) {
  const [serverErrors, setServerErrors] = useState(null);
  const [img, setImg] = useState(null);
  const [status, setStatus] = useState("idle");

  const { setUserIdentity } = useAuth();

  const req = useRequest();
  const { notify } = useUtils();
  const {
    state: { publicKey },
  } = useWallet();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchImage = watch("image", false);

  useEffect(() => {
    if (!watchImage?.length) setValue("image", null);
    else
      setImg({
        url: URL.createObjectURL(watchImage[0]),
        name: watchImage.name,
      });
  }, [watchImage, setValue]);

  const createImage = useCallback(
    (binFile, name, type) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `upload/${name}`,
            binFile,
            {
              method: "POST",
              headers: {
                accept: "*/*",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": type,
              },
            },
            true
          );
          resolve(resData);
        } catch (e) {
          reject(e);
        }
      });
    },
    [req]
  );

  const onSubmit = async (values) => {
    setStatus("loading");
    let descUri = null;
    let imageData = null;
    try {
      if (values.image.length) {
        const binFile = await blob2bin(values.image[0]);
        imageData = await createImage(
          binFile,
          values.image[0].name,
          values.image[0].type
        );
      }

      descUri = await createImage(
        {
          brand: values.brand,
          description: values.description,
          image: imageData ? imageData.Hash : null,
        },
        values.name,
        "application/json"
      );

      await req(
        "identities/",
        {
          identity_name: values.name,
          wallet_public_key: publicKey,
          identity_desc_uri: descUri.Hash,
        },
        { method: "POST" },
        true
      );

      setUserIdentity(publicKey);

      notify("Identity has been created successfully.");
      next();
    } catch (e) {
      console.log("error", e);
      setServerErrors(e.errors);
    } finally {
      setStatus("idle");
    }
  };

  return (
    <div id="create-password">
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 80, ta: "left", mxw: 400, mx: "auto" }}
      >
        {serverErrors?.non_field_errors && <Type sx={{c: 'red', mb: 30, fw: 600 }}>{serverErrors?.non_field_errors[0]}</Type>}
        <InputText
          label="Identity Name"
          name="name"
          error={Boolean(errors.name || serverErrors?.name)}
          msg={errors.name?.message || displayErrors(serverErrors?.name)}
          inputProps={{
            ...register("name"),
            autoFocus: true,
            required: true,
          }}
        />

        <InputText
          label="Brand"
          name="brand"
          error={Boolean(errors.brand || serverErrors?.brand)}
          msg={errors.brand?.message || displayErrors(serverErrors?.brand)}
          inputProps={{
            ...register("brand"),
            required: true,
          }}
        />

        <InputText
          type="textarea"
          label="Description"
          name="description"
          error={Boolean(errors.description || serverErrors?.description)}
          msg={
            errors.description?.message ||
            displayErrors(serverErrors?.description)
          }
          inputProps={{
            ...register("description"),
            rows: 3,
            required: true,
          }}
        />

        <ImageUpload
          label="Upload logo"
          setValue={setValue}
          watch={watch}
          error={Boolean(errors.description || serverErrors?.description)}
          msg={errors.image?.message || displayErrors(serverErrors?.image)}
          buttonLabel="Choose an image"
          inputProps={{
            // name: f.name,
            ...register("image"),
          }}
        />

        {/* <Card sx={{ my: 20, ta: "left" }}>
          {img && (
            <img
              alt="upload preview"
              // sx={{ mb: 1 }}
              src={img.url}
            />
          )}

          <Type gutterBottom variant="h6" component="div">
            {img?.name || "Upload image from device"}
          </Type>
          <Type variant="body2" color="text.secondary">
            An image will add authenticity value to your product
          </Type>

          <Box>
            <label htmlFor="upload-image">
              <InputText
                accept="image/*"
                id="upload-image"
                type="file"
                {...register("image")}
              />
              <Button size="small" variant="outlined" as="span">
                {img ? "Change" : "Choose An"} Image
              </Button>
            </label>
            {img && (
              <Button
                size="small"
                onClick={() => {
                  setImg(null);
                  setValue("image", []);
                }}
              >
                Remove
              </Button>
            )}
          </Box>
          {Boolean(errors.image) && (
            <Type sx={{ mt: 20, ml: 20, c: "red" }}>
              {errors.image?.message}
            </Type>
          )}
        </Card> */}

        <Button sx={{ mt: 30 }} loading={isSubmitting || status === "loading"}>
          Create Identity
        </Button>
        {(isSubmitting || status === "loading") && (
          <Type sx={{ mt: 20 }}>
            Please note that this action may take a while
          </Type>
        )}
      </Box>
    </div>
  );
}

export default WalletIdentity;
