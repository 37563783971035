import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useProducts } from "./productProvider";
import { removeEmpty } from "utilities/functions";
import { Box, Button, Type } from "ui";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import useExpandFieldsFromSchema from "./hooks/useExpandFieldsFromSchema";
import FormFields from "components/FormFields";
import { useTags } from "views/tags/provider";

function ProductForm({
  product = null,
  productSchema,
  productSchemaFields,
  itemSchema,
  handleSuccess,
}) {
  const [error, setError] = useState(null);
  // we are storing tag object so we can use it on product dispatch
  // because the server return tag attribute as id 
  const [tag, setTag] = useState(null);

  const productSchemaId = product
    ? product.product_fields_schema
    : productSchema.id;
  const itemSchemaId = product ? product.item_fields_schema : itemSchema.id;

  const formFields = useExpandFieldsFromSchema(
    itemSchemaId,
    productSchemaId,
    productSchemaFields,
    Boolean(product)
  );

  const { createProduct, editProduct } = useProducts();
  const {
    state: { list: tags },
    fetchList,
  } = useTags();

  const yupSchema = formFields.reduce(createYupSchema, {});
  const schema = Yup.object().shape(yupSchema);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  // set default values if editing
  useEffect(() => {
    if (!product) return;
    setValue("name", product.name);
    setValue("tag", product.tag?.id);
    if (product["json_data"])
      Object.keys(product["json_data"]).forEach((k) => {
        setValue(k.toString(), product["json_data"][k]);
      });
  }, [product, setValue]);

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      tag: values.tag,
    };

    if (!product) {
      data.product_fields_schema = values.product_fields_schema;
      data.item_fields_schema = values.item_fields_schema;
    }

    delete values.product_fields_schema;
    delete values.item_fields_schema;
    delete values.name;
    delete values.tag;

    // don't send empty object properties to server
    data.save_json_data = removeEmpty(values);

    try {
      if (product) await editProduct(product.id, data);
      else {
        await createProduct(data, tag);
        // await createProduct(data);
        reset();
      }
      handleSuccess();
    } catch (e) {
      setError(
        <>
          <div>Server error.</div>
          {e.errors.data?.map((e) => (
            <p>{e}</p>
          ))}
        </>
      );
      console.log("e", e);
    }
  };

  const handleValue = (fieldName, data) => {
    setValue(fieldName, data.id);
    if(fieldName === 'tag') setTag(data)
  }

  if (!tags.length) return null;

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1, maxWidth: 400 }}
    >
      {error && (
        <Type sx={{ mt: 3, mb: 2, c: "red" }} as="div">
          {error}
        </Type>
      )}

      <FormFields
        fields={formFields}
        errors={errors}
        register={register}
        resources={{ tags }}
        setValue={handleValue}
        getValues={getValues}
      />

      <Button type="submit" sx={{ mt: 30, mb: 20 }} loading={isSubmitting}>
        {product ? "Save" : "Create"}
      </Button>
    </Box>
  );
}

export default memo(ProductForm);
