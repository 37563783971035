// import { emptyStringToNull } from "../../utilities/yupSchemaCreator";

const FILE_SIZE = 1024 * 1024;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/webp",
];

const fields = [
  {
    name: "name",
    type: "text",
    label: "Item Name",
    validationType: "string",
    required: true,
    validations: [
      {
        type: "required",
        params: ["this field is required."],
      },
    ],
  },
  {
    name: "count",
    type: "number",
    label: "Quantity",
    hidden: "edit",
    validationType: "number",
    required: true,
    validations: [
      {
        type: "min",
        params: [1, "Must be at least 1"],
      },
      {
        type: "max",
        params: [100000, "Maximum 100000"],
      },
      {
        type: "typeError",
        params: ["A number is required"],
      },
      {
        type: "required",
        params: ["this field is required."],
      },
    ],
  },
  {
    name: "description",
    type: "textarea",
    label: "Description",
    validationType: "string",
    required: true,
    validations: [
      {
        type: "min",
        params: [3],
      },
      {
        type: "required",
        params: ["this field is required."],
      },
    ],
  },
  {
    name: "image",
    hidden: "edit",
    buttonLabel: "Upload image",
    type: "image",
    label: "Upload Item Image",
    validationType: "mixed",
    required: true,
    validations: [
      {
        type: "required",
        params: ["this field is required."],
      },
      {
        type: "test",
        params: [
          "fileSize",
          "File too large",
          (value) => null || (value && value[0]?.size <= FILE_SIZE),
        ],
      },
      {
        type: "test",
        params: [
          "fileFormat",
          "Unsupported Format",
          (value) =>
            null || (value && SUPPORTED_FORMATS.includes(value[0]?.type)),
        ],
      },
    ],
  },
];

export default fields;
