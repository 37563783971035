import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../services/auth";
import { Box, Card, Divider, Flex, Icon, List, Tooltip, Type } from "ui";
import { useUtils } from "services/utilsProvider";
import Dropdown from "ui/core/Dropdown";

function AccountInfo() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { notify } = useUtils();

  return (
    <Box>
      <Card>
        <Type var="h5" as="h5" sx={{mb: 5}}>
          Personal info
        </Type>
        <Type sx={{mb: 10}}>Personal info and options to manage it.</Type>

        <List>
          <Flex
            as="li"
            jc="space-between"
            sx={{ cursor: "pointer", ":hover": { bgc: "gray100" } }}
            onClick={() => navigate("username")}
          >
            <Flex jc="flex-start" gap={10}>
              <Type sx={{pl: 5}}>Username: </Type>
              <Type sx={{ fw: 500 }}>{auth.user.user.username}</Type>
            </Flex>
            <Box sx={{pr: 5}}>
              <Icon name="pen" w={14} c="gray600" />
            </Box>
          </Flex>
          <Flex
            as="li"
            jc="space-between"
            sx={{ cursor: "pointer", ":hover": { bgc: "gray100" } }}
            onClick={() => navigate("email")}
          >
            <Flex jc="flex-start" gap={10}>
              <Type sx={{pl: 5}}>Email: </Type>
              <Type sx={{ fw: 500 }}>{auth.user.user.email}</Type>
            </Flex>
            <Box sx={{pr: 5}}>
              <Icon name="pen" w={14} c="gray600" />
            </Box>
          </Flex>
          <Flex
            jc="space-between"
            sx={{ py: 14, cursor: "pointer", ":hover": { bgc: "gray100" } }}
            onClick={() => navigate("name")}
          >
            <Flex jc="flex-start" gap={10}>
              <Type sx={{pl: 5}}>Name: </Type>
              <Type sx={{ fw: 500 }}>
                {auth.user.first_name} {auth.user.user.last_name}
              </Type>
            </Flex>
            <Box sx={{pr: 5}}>
              <Icon name="pen" w={14} c="gray600" />
            </Box>
          </Flex>
        </List>

        <Divider border="gray300" h={60} />

        <Type var="h5" as="h5" sx={{mb: 5}}>
          Password
        </Type>
        <Type sx={{mb: 10}}>A secure password helps protect your Stitchain Account.</Type>

        <List>
          <Flex
            jc="space-between"
            sx={{ py: 14, cursor: "pointer", ":hover": { bgc: "gray100" } }}
            onClick={() => navigate("password")}
          >
            <Type sx={{ fs: 34, pl: 5 }}>••••••••</Type>
            <Box sx={{pr: 5}}>
              <Icon name="pen" w={14} c="gray600" />
            </Box>
          </Flex>
        </List>

        <Divider border="gray300" h={60} />

        <Type var="h5" as="h5" sx={{mb: 5}}>
          Public Address
        </Type>
        <Type sx={{mb: 10}}>
          Use this public access to identify you brand in block chain
          transactions
        </Type>

        <Flex jc="flex-start" gap={10}>
          <Type
            sx={{ fw: 500, my: 40 }}
            onClick={() => {
              navigator.clipboard.writeText(
                auth.user.user.manufacturer.identity_address
              );
              notify("Content copied");
            }}
          >
            {auth.user.user.manufacturer.identity_address}
          </Type>
          <span className="h-sm">
            <Dropdown
              click={false}
              placement="bottom"
              content={<Tooltip>Copy Address</Tooltip>}
            >
              <Icon name="copy" w={14} c="gray400" />
            </Dropdown>
          </span>
        </Flex>
      </Card>
    </Box>
  );
}

export default AccountInfo;
