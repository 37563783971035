import { useState } from "react";
import { Box, Grid } from "../ui";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import styled from "styled-components";

const SGrid = styled(Grid)`
  height: 100vh;
  transition: all 0.2s ease-in;
`;

function Layout({ children }) {
  const [open, setOpen] = useState(true);

  return (
    <SGrid
      className={open ? "open" : ""}
      cols={open ? "240px 1fr" : "60px 1fr"}
      rows="max-content 1fr"
    >
      <Header sx={{ gc: "1 / 3" }} />
      <Sidebar
        sx={{ bgc: "gray900" }}
        setOpen={setOpen}
        open={open}
      />
      <Box as="main" sx={{ bgc: "gray200" }}>
        <Box sx={{ p: 40 }}>{children}</Box>
      </Box>
    </SGrid>
  );
}

export default Layout;
