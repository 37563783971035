import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useStats() {
  return useContext(Context);
}

const initState = {
  totals: {},
  verificationTransaction: [],
  transactionsLocations: [],
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_totals":
      return { ...state, totals: { ...action.payload } };
    case "set_verfication_transaction":
      return { ...state, verificationTransaction: [...action.payload] };
    case "set_transactions_locations":
      return { ...state, transactionsLocations: [...action.payload] };
    case "status":
      return { ...state, status: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function StatsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchTotals = useCallback(async (params = {days_ago: 100}) => {
    const searchParams = new URLSearchParams(params);
    console.log('searchParams', searchParams)
    dispatch({ type: "status", payload: "fetching" });
    const resData = await req(`stats/totals/?${searchParams.toString()}`, null, {}, true);
    dispatch({ type: "status", payload: `idle` });
    dispatch({ type: "set_totals", payload: resData });
  }, [req]);

  const fetchVerificationTransaction = useCallback(async () => {
    dispatch({ type: "status", payload: "fetching" });
    const resData = await req(
      `stats/verification-transactions/`,
      null,
      {},
      true
    );
    dispatch({ type: "status", payload: `idle` });
    dispatch({ type: "set_verfication_transaction", payload: resData.results });
  }, [req]);

  const fetchTransactionsLocations = useCallback(async () => {
    dispatch({ type: "status", payload: "fetching" });
    const resData = await req(
      `stats/transactions_by_country/`,
      null,
      {},
      true
    );
    dispatch({ type: "status", payload: `idle` });
    dispatch({ type: "set_transactions_locations", payload: resData });
  }, [req]);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchTotals,
        fetchVerificationTransaction,
        fetchTransactionsLocations,
      }}
    >
      {children}
    </Context.Provider>
  );
}
