import Layout from "layout/Layout";
import { Box, Flex, Icon, Type } from "ui";

function NoAccess() {
  return (
    <Layout>
      <Box
        center
        w={400}
        sx={{
          ta: 'center'
        }}
      >
        <span>
          <Flex sx={{ mt: 40, bgc: "primary", br: 999, w: 40, h: 40, d: 'inline-flex' }}>
            <Icon name="lock" c="white" w={14} />
          </Flex>
        </span>

        <Type var="h3" as="h3" sx={{ my: 20, fw: 500 }}>
          Access Denied
        </Type>
        <Type var="bas" sx={{ ta: "center" }}>
          Your account dosn't have permission to access these contents.
        </Type>
      </Box>
    </Layout>
  );
}

export default NoAccess;
