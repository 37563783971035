import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useOrders() {
  return useContext(Context);
}

const initState = {
  list: [],
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
};


const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_count":
      return { ...state, count: action.payload };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function OrderProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (page = 0, pageSize = 10) => {
      dispatch({ type: "status", payload: "fetching" });
      const query = `page=${page + 1}&page_size=${pageSize}`;
      const resData = await req(`pos-orders/?${query}`, null, {}, true);
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.results });
      dispatch({ type: "set_count", payload: resData.count });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
      }}
    >
      {children}
    </Context.Provider>
  );
}
