import { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserForm from "./UserForm";
import { useUtils } from "../../services/utilsProvider";
import { Box, Flex, Type } from "ui";
import Layout from "layout/Layout";

function AddUser() {
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = (id) => {
    navigate(`/users`);
    notify(
      "User saved successfully, An email has been sent to user on how to set password"
    );
  };

  return (
    <Layout sx={{ maxWidth: 500, p: 2 }} id="add-user">
      <Flex jc="flex-start" aria-label="breadcrumb" gap={5}>
        <Link to="/">Home</Link>
        <Type>-</Type>
        <Link to="/users">Users</Link>
      </Flex>
      <Type var="h3" as="h3" sx={{ mt: 18 }}>
        Add new user
      </Type>

      <Type sx={{ mt: 10, mxw: 520 }}>
        The new user will recieve, by email, the intruction on how to set a
        password for his newly created account.
      </Type>
      <Box sx={{ mt: 40, p: 0 }}>
        <UserForm handleSuccess={handleSuccess} />
      </Box>
    </Layout>
  );
}

export default memo(AddUser);
