import styled, { css } from "styled-components";
import Input from "./Input";
import Label from "./Label";
import Type from "./Type";
import Icon from "./Icon";
import stylesBlocks from "./core/stylesBuilder";

const Wrapper = styled.div`
  margin-bottom: 25px;
  ${(p) =>
    p.noSpace &&
    css`
      margin-bottom: 0;
    `}
  .inputWrap {
    position: relative;
    &.icon-left {
      input {
        padding-left: 45px;
      }
    }
    > span {
      position: absolute;
      &.left {
        left: 13px;
      }
      &.right {
        right: 13px;
      }
      top: 50%;
      transform: translate(0, -50%);
      pointer-events: none;
      &.clickable {
        pointer-events: auto;
        cursor: pointer;
      }
      line-height: 0;
      svg {
        width: 16px;
        fill: ${(p) => p.theme.color.gray700};
      }
    }
  }
  ${stylesBlocks}
`;

function InputText({
  label,
  value,
  name,
  id,
  onChange,
  placeholder = "",
  type,
  msg = null,
  error = false,
  iconLeft = null,
  iconRight = null,
  size = "large",
  inputProps,
  ...props
}) {
  return (
    <Wrapper {...props} className={`${error && "error"}`}>
      {label && <Label htmlFor={id || inputProps?.id}>{label}</Label>}
      <div
        className={`inputWrap ${iconLeft && "icon-left"} ${
          iconRight && "icon-right"
        }`}
      >
        {iconLeft && (
          <span
            className={`left ${typeof iconLeft !== "string" && "clickable"}`}
            onClick={typeof iconLeft === "string" ? undefined : iconLeft[1]}
          >
            <Icon
              name={typeof iconLeft === "string" ? iconLeft : iconLeft[0]}
            />
          </span>
        )}
        {iconRight && (
          <span
            className={`right ${typeof iconRight !== "string" && "clickable"}`}
            onClick={typeof iconRight === "string" ? undefined : iconRight[1]}
          >
            <Icon
              name={typeof iconRight === "string" ? iconRight : iconRight[0]}
            />
          </span>
        )}
        {/* {icons &&
          icons.map(
            (icon) =>
              icon && (
                <span
                  className={icon[1]}
                  onClick={icon[2] ? icon[2] : undefined}
                >
                  <Icon name={icon[0]} />
                </span>
              )
          )} */}
        <Input
          as={type === "textarea" ? "textarea" : "input"}
          id={id || inputProps?.id}
          aria-label={id || inputProps?.id || label || placeholder}
          isValid={!error}
          {...{
            value,
            type,
            name,
            id,
            onChange,
            placeholder,
            size,
            ...inputProps,
          }}
        />
      </div>
      {msg && <Type sx={{ c: error ? "red" : "gray600", mt: 8 }}>{msg}</Type>}
    </Wrapper>
  );
}

export default InputText;
