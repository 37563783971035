import { useState, useRef, memo } from "react";
import { useSchemas } from "./schemaProvider";
import { Access } from "../../services/auth";
import { Box, Button, Flex, Grid, Icon, Type } from "ui";
import Layout from "layout/Layout";
import Modal from "ui/Modal";
import SchemaForm from "./SchemaForm";
import SchemaList from "./SchemaList";
import Fields from "views/fields/Fields";

function Schemas() {
  const inputEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [schema, setSchema] = useState(null);

  const {
    state: { list, selectedId, status }
  } = useSchemas();

  const handleOpen = () => setOpen(true);

  const handleEdit = (schema) => {
    setEdit(true);
    setSchema(schema);
    handleOpen();
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setEdit(false);
      setSchema({name: ""});
    }, 100);
  };

  const onSuccess = () => {
    handleClose();
  };

  // const handleSubmit = async () => {
  //   if (!inputSchemaName || inputSchemaName === "") {
  //     setError("Spec name is required");
  //     // inputEl.current.focus();
  //     return;
  //   }
  //   await createSchema(inputSchemaName, edit);
  //   handleClose();
  // };

  return (
    <Layout id="schemas">
      <Flex jc="space-between" sx={{ mb: 60 }}>
        <Box>
          <Type var="h3" as="h3" sx={{ mt: 0, mb: 0 }}>
            Specs
          </Type>
          <Type>Create and manage specs and fields.</Type>
        </Box>
        <Access path="/schemas/new">
          <Button onClick={handleOpen} iconName="schema">
            Add New Spec
          </Button>
        </Access>
      </Flex>
      
      <Modal show={open}>
        <Box
          abs={{ t: 10, r: 10 }}
          sx={{ z: 999, cursor: "pointer" }}
          onClick={handleClose}
        >
          <Icon hover name="xmark" w={14} c="black" />
        </Box>
        <SchemaForm
          // schema={list.find((l) => l.id === selectedId)}
          {...{
            inputEl,
            edit,
            schema,
            onSuccess,
          }}
        />
      </Modal>

      <Grid cols="1fr 3fr" gap={30}>
        <SchemaList />

        {Boolean(list?.length) && <Fields handleEdit={handleEdit} />}
      </Grid>
    </Layout>
  );
}

export default memo(Schemas);
