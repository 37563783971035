import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useProducts } from "./productProvider";

function Products() {
  const { dispatch } = useProducts();

  useEffect(() => {
    return () => {
      dispatch({ type: "set_per_page", payload: 10 });
      dispatch({ type: "set_page", payload: 0 });
    };
  }, [dispatch]);

  return <Outlet />;
}

export default Products;
