import styled from "styled-components";

const Label = styled.label`
  font-size: 14px;
  color: ${(props) => props.theme.color.gray700};
  display: block;
  margin-bottom: 10px;
  padding-left: 2px;
  font-weight: 500;
`;

export default Label;
