import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useUsers } from "./usersProvider";

function Users() {
  const { dispatch } = useUsers();

  useEffect(() => {
    return () => {
      dispatch({ type: "set_per_page", payload: 10 });
      dispatch({ type: "set_page", payload: 0 });
    };
  }, [dispatch]);

  return <Outlet />;
}

export default Users;
