import { Card, Flex, InputRadio, Type } from "ui";

const FilterForm = ({ title, name, filter, setFilter }) => {
  const handleChange = (event) => {
    setFilter(event.target.value);
  };
  return (
    <Card sx={{ minWidth: 200, mb: 0, px: 2, py: 1 }}>

        <Type id={`${name}-filter`} sx={{mb: 15}}>{title}</Type>
        <Flex
          gap={15}
          value={filter}
          onChange={handleChange}
          name={name}
        >
          <InputRadio value="1" label="Yes" name={name} inputProps={{value: '1'}} />
          <InputRadio value="0" label="No" name={name} inputProps={{value: '0'}} />
          <InputRadio value="all" label="Both" name={name} inputProps={{value: 'all'}} />
        </Flex>

    </Card>
  );
};

const BoundFilter = ({ filter, setFilter }) => {
  return (
    <FilterForm
      title="Is Bound to a card?"
      name="bound"
      {...{ filter, setFilter }}
    />
  );
};

const NftFilter = ({filter, setFilter}) => {
  return (
    <FilterForm
      title="Has recieved NFT?"
      name="nft"
      {...{ filter, setFilter }}
    />
  );
};

export { BoundFilter, NftFilter };
