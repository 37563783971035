import { useEffect, useState } from "react";
import useImageLink from "utilities/hooks/useImageLink";
import FileField from "components/FileField";
import { Box, Type } from "ui";

function ImageUpload({
  url = null,
  name,
  label,
  setValue,
  watch,
  loading,
  buttonLabel,
  error,
  msg,
  inputProps,
}) {
  const imgUrl = useImageLink(url);
  const [preview, setPreview] = useState(null);

  const watchImage = watch("image", false);

  useEffect(() => {
    if (!watchImage?.length) setValue("image", null);
    else setPreview(URL.createObjectURL(watchImage[0]));
  }, [watchImage, setValue]);

  // const handleChange = async (e) => {
  //   const file = e.target.files[0];
  //   setPreview(URL.createObjectURL(file));
  //   // setFile(file);
  //   setValue(file);
  //   // notify("Image has been saved successfully.");
  // };

  return (
    <Box sx={{ mb: 30 }}>
      <FileField
        label={label}
        name={name}
        buttonLabel={buttonLabel}
        preview={imgUrl || preview}
        setPreview={setPreview}
        loading={loading}
        inputProps={{
          // name,
          // onChange: handleChange,
          accept: "image/jpng,image/png,image/jpg,image/jpeg",
          ...inputProps,
        }}
      />
      <Type sx={{ c: "red", mt: 10 }}>{msg}</Type>
    </Box>
  );
}

export default ImageUpload;
