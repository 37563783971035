import { Box, Loading, Pagination, Type } from "ui";
import SchemaRow from "./SchemaRow";
import { useSchemas } from "./schemaProvider";
import { useEffect, useState } from "react";

function SchemaList() {
  const [page, setPage] = useState(0);

  const {
    state: { list, selectedId, status, count },
    dispatch,
    fetchList,
    deleteSchema,
    cloneSchema,
  } = useSchemas();

  useEffect(() => {
    fetchList(page);
  }, [fetchList, page]);

  const handleToggle = (id) => () => {
    dispatch({ type: "set_selected", payload: id });
  };
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure?")) await deleteSchema(id);
  };

  const handleClone = (id) => cloneSchema(id);

  const handleChangePage = (newPage) => setPage(newPage);

  return (
    <Box>
      <Box sx={{ w: "100%", bgc: "white", br: 4, mb: 16 }}>
        <Type var="h6" as="h6" sx={{p: 15, border: ['gray300', 1, 'solid', 0, 'b']}}>All Specs</Type>
        {list.map((item) => (
          <SchemaRow
            key={item.id}
            schema={item}
            role="listitem"
            {...{
              status,
              selectedId,
              handleToggle,
              handleDelete,
              handleClone,
            }}
          />
        ))}
        {!list?.length && status === "loading" && <Loading />}

        {!list?.length && status === "idle" && (
          <Type my={4} textAlign="center">
            No spec has been created yet.
          </Type>
        )}
      </Box>
      <Pagination
        data-testid="schema-pagination"
        count={count}
        page={page}
        pager={false}
        info={true}
        onPageChange={handleChangePage}
      />
    </Box>
  );
}

export default SchemaList;
