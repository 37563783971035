import { useState } from "react";
import { useRequest } from "services/request";
import { useUtils } from "services/utilsProvider";
import { useWallet } from "./WalletProvider";
import { Box, Button, Type } from "ui";

const WalletApprove = ({ next }) => {
  const [status, setStatus] = useState("idle");

  const req = useRequest();
  const { notify } = useUtils();
  const {
    state: { publicKey, privateKey },
    dispatch,
  } = useWallet();

  const handleClick = async () => {
    try {
      setStatus("loading");
      await req(
        `identities/${publicKey}/approve/`,
        {
          private_key: privateKey,
          approved: true,
        },
        { method: "POST" },
        true
      );

      dispatch({ type: "set_has_approved", payload: true });

      notify("Access has been granted.");
      next();
    } catch (e) {
      console.log("error", e);
    } finally {
      setStatus("idle");
    }
  };

  return (
    <div>
      <Box sx={{ mt: 80, textAlign: "center", maxWidth: 400, mx: "auto" }}>
        <Type sx={{ fw: 500 }}>Allow Stitchain to use your wallet</Type>
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{ mt: 30 }}
          loading={status === "loading"}
        >
          Allow
        </Button>
      </Box>
    </div>
  );
};

export default WalletApprove;
