import { useEffect } from "react";
import { useRequest } from "../../services/request";
import { Box, Button, Flex, Grid, Icon, List, Type } from "ui";

// const wordsFieldStyles = {
//   display: "grid",
//   gridTemplateColumns: {
//     xs: "repeat(4, 1fr)",
//     md: "repeat(6, 1fr)",
//   },
//   rowGap: "30px",
//   p: 2,
//   mb: 4,
//   mt: 1,
//   "& section": {
//     cursor: "pointer",
//     transition: "all 0.2s ease-in",
//     "& div:first-of-type": {
//       padding: "4px",
//       margin: "4px",
//       borderBottom: "#ccc 1px solid",
//     },
//     "& div:last-of-type": {
//       color: "#666",
//       fontSize: "13px",
//     },
//     "& div span": {
//       opacity: 0,
//       transition: "all 0.2s ease-in",
//       "@media print": {
//         opacity: 1,
//       },
//     },
//     "&:hover": {
//       transform: "translateY(5px)",
//       "& div span": {
//         opacity: 1,
//       },
//     },
//   },
// };

function WalletSecure({ next, words, setWords }) {
  const req = useRequest();

  useEffect(() => {
    (async () => {
      const resData = await req(`mnemonics/`, null, {}, true);
      setWords(resData.words.split(" "));
    })();
  }, [req, setWords]);

  const handleContinue = () => {
    next();
  };

  return (
    <div id="create-password">
      <Box sx={{ mt: 50, ta: "center" }}>
        <Type var="h5" sx={{ mb: 10 }}>
          Secret Recovery Phrase
        </Type>
        <Type
          sx={{
            mxw: 500,
            mb: 20,
            fw: 500,
            mx: "auto",
            "@media print": { display: "none" },
          }}
        >
          These {words.length} words protect your wallet and make it easy to
          backup and recover your wallet.
        </Type>

        <Flex
          gap="15"
          dir="column"
          sx={{
            my: 30,
            mxw: 600,
            mx: "auto",
            p: 20,
            bgc: "blueBg",
            border: ["gray200", 1, "solid", 4],
            print: { d: "none" }
          }}
        >
          <Icon name="info" w={18} c="blue" />
          <Type sx={{ c: "blue" }}>
            Your secret recovery phrase is hidden for security. Mouseover the
            number to reveal.
          </Type>
        </Flex>

        <Grid
          cols={[
            "repeat(6, 1fr)",
            "repeat(4, 1fr)",
            "repeat(3, 1fr)",
            "repeat(2, 1fr)",
          ]}
          rowGap={20}
          sx={{ p: 20, my: 0 }}
        >
          {words.map((w, i) => (
            <Box
              as="section"
              relative
              sx={{
                c: "white",
                ":hover": {
                  c: "black",
                  cursor: "pointer",
                  transform: "translate(0, 2px)",
                  tr: "all .1s ease-in",
                },
              }}
              key={`${w}${i}`}
            >
              <Box sx={{ p: 4, m: 4, border: ["gray300", 1, "solid", 0, "b"], print: { c: "black" } }}>
                {w}
              </Box>
              <Box sx={{ c: "gray600", fs: 13, pt: 10 }}>{i + 1}</Box>
            </Box>
          ))}
        </Grid>

        <Button
          variant="outlined"
          sx={{ mt: 30, mr: 30, print: { d: "none" } }}
          onClick={() => window.print()}
          iconName="print"
        >
          Print Phrase
        </Button>
        <Button
          sx={{ mt: 20, print: { d: "none" } }}
          onClick={handleContinue}
          iconName="arrowRight"
        >
          Continue
        </Button>

        <Type sx={{ fw: 600, mt: 60, ta: "left" }}>
          Key Tips:
        </Type>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            textAlign: "left",
            listStyle: "auto",
            ml: 2,
          }}
          as="ol"
        >

            <Type as="li" sx={{ta: 'left'}}>
              Never share your recovery phrase. May anyone with this phrase own
              your items forever.
            </Type>

            <Type as="li" sx={{ta: 'left'}}>
              Write this phrase down on paper and store it securely offline in
              2-3 different locations.
            </Type>

            <Type as="li" sx={{ta: 'left'}}>Memorize this phrase.</Type>

        </List>
      </Box>
    </div>
  );
}

export default WalletSecure;
