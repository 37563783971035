import { useEffect, useState } from "react";
import formFields from "../fields";
import { genFieldsObjFromSchema } from "utilities/projectFunctions";

function useExpandFieldsFromSchema(schemaFields) {
  const [formFieldsExpanded, setFormFieldsExpanded] = useState(formFields);

  useEffect(() => {
    const newFields = [...formFields, ...genFieldsObjFromSchema(schemaFields)];

    setFormFieldsExpanded(newFields);
  }, [schemaFields]);

  return formFieldsExpanded;
}

export default useExpandFieldsFromSchema;
