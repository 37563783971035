import { Box, Button, Flex, Icon, TableContainer, Type } from "ui";
import { useStats } from "views/stats/statsProvider";
import TransactionMap from "./TransactionMap";
import Modal from "ui/Modal";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { GOOGLE_APT_KEY } from "config";

const style = {
  backgroundColor: "#f5f5f5",
  color: "#444",
  fontWeight: "600",
  borderBottom: "#ddd 1px solid",
};

const cols = [
  {
    name: "id",
    align: "left",
    disablePadding: false,
    label: "ID",
    props: { style },
  },
  {
    name: "verification_hash",
    align: "left",
    disablePadding: false,
    label: "Verification Hash",
    props: { style },
  },
  {
    name: "card_id",
    align: "left",
    disablePadding: false,
    label: "Card ID",
    props: { style },
  },
  {
    name: "is_active",
    align: "left",
    disablePadding: true,
    label: "Is Active",
    props: { style },
    render: (row) => (
      <Icon
        name={row.is_active ? "check" : "check"}
        c={row.is_active ? "green" : "gray300"}
        w={15}
      />
    ),
  },
  {
    name: "scanned_on",
    align: "left",
    disablePadding: false,
    label: "Scanned On",
    render: (row) => {
      return (
        <Type>
          {format(new Date(row.scanned_on), "MMMM dd, yyyy hh:mm aaa")}
        </Type>
      );
    },
    props: {
      style: {
        backgroundColor: "#f5f5f5",
        color: "#444",
        fontWeight: "600",
        borderBottom: "#ddd 1px solid",
      },
    },
  },
  {
    name: "location",
    align: "right",
    disablePadding: false,
    label: "Location",
    props: {
      style: {
        backgroundColor: "#f5f5f5",
        color: "#444",
        fontWeight: "600",
        borderBottom: "#ddd 1px solid",
        minWidth: "210px",
      },
    },
    render: function OpenMap(row) {
      const [open, setOpen] = useState(false);
      const [country, setCountry] = useState("");

      useEffect(() => {
        if (!row.location) return;
        (async () => {
          const res = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${row.location?.latitude},${row.location?.longitude}&key=${GOOGLE_APT_KEY}`
          );
          const countryName = (
            await res.json()
          ).results[0].address_components.find((item) =>
            item.types.includes("country")
          ).long_name;

          setCountry(countryName);
        })();
      }, [row.location]);

      return row.location ? (
        <>
          <Button variant="text" sx={{ml: -8}} onClick={() => setOpen(true)} iconName="map">
            {country}
          </Button>
          <Modal onClose={() => setOpen(false)} show={open}>
            <TransactionMap
              lng={row.location?.longitude}
              lat={row.location?.latitude}
            />
          </Modal>
        </>
      ) : (
        "-"
      );
    },
  },
];

export default function VerificationTransactionTable({ data }) {
  const {
    state: { status },
  } = useStats();

  return (
    <Box>
      <TableContainer
        empty={data?.length === 0}
        status={status}
        data={data}
        aria-label="Last orders table"
        cols={cols}
        // moreOptions={[
        //   {name: "transaction", render: (row) => <Type sx={{cursor: 'pointer', c: 'primary'}}>View Transaction</Type>}
        // ]}
      />
    </Box>
  );
}
