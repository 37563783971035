import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWallet } from "./WalletProvider";
import Layout from "layout/Layout";
import { Type } from "ui";
import { useAuth } from "services/auth";

function WalletShow() {
  const navigate = useNavigate();
  const {
    state: { privateKey, hasApproved },
    fetchHasApproved,
  } = useWallet();

  const {user} = useAuth();

  // useEffect(() => {
  //   if(hasApproved !== null) return;
  //   fetchHasApproved();
  // }, [fetchHasApproved, hasApproved]);

  useEffect(() => {
    // if (user.user.manufacturer.identity_address)
    if(hasApproved === null && user.user.manufacturer.identity_address) {
      (async () => await fetchHasApproved())()
    }
    if (window.localStorage.getItem("wallet_public_key"))
      if (!privateKey) navigate("/wallet/unlock");
    
      if(!hasApproved) navigate("/wallet/create");
      // if (privateKey) navigate("/wallet");
      // else navigate("/wallet/unlock");
  }, [navigate, privateKey, hasApproved, fetchHasApproved, user]);

  return (
    <Layout>
      <Type>This is your wallet details</Type>
    </Layout>
  );
}

export default WalletShow;
