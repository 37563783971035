import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Flex, Icon, Link, Tooltip } from "../../ui";
import Dropdown from "ui/core/Dropdown";
import { Access } from "services/auth";

const links = [
  { name: "Dashboard", path: "/dashboard", icon: "dashboard" },
  { name: "Specs", path: "/schemas", icon: "schema" },
  { name: "Products", path: "/products", icon: "products" },
  { name: "Users", path: "/users", icon: "userCadre" },
  { name: "Tags", path: "/tags", icon: "tags" },
  { name: "Verification Transactions", path: "/verification-transactions", icon: "certificate" },
  // { name: "Wallet", path: "/wallet/import", icon: "wallet" },
];

if (window.localStorage.getItem("wallet_public_key")) {
  links.push({ name: "Wallet", path: "/wallet", icon: "wallet" });
} else {
  links.push({
    name: "Import Wallet",
    path: "/wallet/import",
    icon: "wallet",
    access: "/wallet",
  });
}

const StyledNav = styled.nav`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      &:first-child {
        a {
          border-top: ${(p) => p.theme.color.white}11 1px solid;
        }
      }
      span:last-child {
        min-width: 200px;
        display: block;
        opacity: ${(p) => Number(p.isOpen)};
        transition: opacity 0.2s ease-${(p) => (p.isOpen ? "in" : "out")};
      }
      a {
        color: ${(props) => props.theme.color.white};
        text-decoration: none;
        padding: 16px;
        font-weight: 500;
        font-size: 14px;
        border-bottom: ${(p) => p.theme.color.white}11 1px solid;
      }
      &:hover {
        a {
          background-color: ${(p) => p.theme.color.white}11;
          color: ${(p) => p.theme.color.gray100};
        }
      }
      &.selected {
        a {
          background-color: ${(p) => p.theme.color.black}cc;
          border-left-color: ${(p) => p.theme.color.black};
        }
      }
      @media only screen and (max-width: 1200px) {
        padding: 0 20px;
      }
    }
  }
  &.md ul {
    display: block;
    text-align: right;
    margin-top: 80px;
    margin-right: 14px;
    li {
      padding: 10px 0;
    }
  }
`;

function Nav({ isOpen, ...props }) {
  const location = useLocation();
  return (
    <StyledNav isOpen={isOpen} {...props}>
      <ul>
        {links.map((link) => (
          <Access path={link.access || link.path} key={link.path}>
            <Dropdown
              placement="right"
              disabled={isOpen}
              click={false}
              content={<Tooltip>{link.name}</Tooltip>}
            >
              <li
                key={link.name}
                className={
                  location.pathname.startsWith(`${link.path}`)
                    ? "selected"
                    : undefined
                }
              >
                <Flex
                  jc="flex-start"
                  gap={10}
                  role="link"
                  as={Link}
                  to={link.path}
                >
                  <Icon name={link.icon} w={16} c="gray200" />{" "}
                  <span>{link.name}</span>
                </Flex>
              </li>
            </Dropdown>
          </Access>
        ))}
      </ul>
    </StyledNav>
  );
}

export default Nav;
