import { useEffect, useState } from "react";
import formFields from "../fields";
import { genFieldsObjFromSchema } from "utilities/projectFunctions";

function useExpandFieldsFromSchema(
  itemSchemaId,
  productSchemaId,
  productSchemaFields,
  editing
) {
  const [formFieldsExpanded, setFormFieldsExpanded] = useState(formFields);

  useEffect(() => {
    setFormFieldsExpanded(() => {
      let newFields = [...formFields];

      newFields.push(...genFieldsObjFromSchema(productSchemaFields))

      // In case of a new product
      if (!editing) {
        newFields.push({
          name: "product_fields_schema",
          type: "hidden",
          validationType: "number",
          required: true,
          value: productSchemaId,
          validations: [
            {
              type: "required",
              params: ["Product field spec is required."],
            },
          ],
        });

        newFields.push({
          name: "item_fields_schema",
          type: "hidden",
          value: itemSchemaId,
          validationType: "number",
          required: true,
          validations: [
            {
              type: "required",
              params: ["Item field spec is required."],
            },
          ],
        });
      }

      return newFields;
    });
  }, [productSchemaFields, editing, itemSchemaId, productSchemaId]);

  return formFieldsExpanded;
}

export default useExpandFieldsFromSchema;
