import { memo, useEffect, useState } from "react";
import { useSchemas } from "../schemas/schemaProvider";
import ProductForm from "./ProductForm";
import { useUtils } from "../../services/utilsProvider";
import { Box, Card, Flex, Link, Loading, Select, Type } from "ui";
import Layout from "layout/Layout";
import { useFields } from "views/fields/fieldProvider";

function AddProduct() {
  const [productSchema, setProductSchema] = useState(null);
  const [itemSchema, setItemSchema] = useState(null);

  const { notify } = useUtils();
  
  const {
    state: { list: productSchemaFields },
    fetchList: fetchProductSchemaFields,
  } = useFields();

  const {
    state: { list: schemas, status },
    fetchList: fetchSchemas,
  } = useSchemas();

  useEffect(() => {
    fetchSchemas(0, 1000);
  }, [fetchSchemas]);

  useEffect(() => {
    if (!productSchema) return;
    fetchProductSchemaFields(0, 100, productSchema.id);
  }, [productSchema, fetchProductSchemaFields]);

  const handleSchemaChange = ({ data }) => {
    setProductSchema(data);
  };

  const handleItemSchemaChange = ({ data }) => {
    setItemSchema(data);
  };

  const handleSuccess = () => {
    notify("Product created successfully");
    // setActiveStep(0);
    setProductSchema(null);
    setItemSchema(null);
  };

  const steps = [
    {
      label: "Select product spec",
      description: (
        <Select
          style={{ width: "100%" }}
          label="Product Spec"
          id="product_schema"
          onChange={handleSchemaChange}
          data={schemas}
          mapData={{ value: "id", label: "name" }}
          inputProps={{
            value: productSchema || {},
          }}
        />
      ),
    },
    {
      label: "Select item Spec",
      description: (
        <Select
          style={{ width: "100%" }}
          label="Item Spec"
          onChange={handleItemSchemaChange}
          id="product_schema"
          mapData={{ value: "id", label: "name" }}
          data={schemas}
          inputProps={{
            value: itemSchema || {},
          }}
        />
      ),
    },
    {
      label: "Create the product",
      description: Boolean(productSchema && itemSchema) ? (
        <ProductForm
          {...{ productSchema, productSchemaFields, itemSchema, handleSuccess }}
        />
      ) : (
        <Type sx={{c: 'red'}}>Select a product spec and an item spec first.</Type>
      ),
    },
  ];

  return (
    <Layout>
      <Flex jc="flex-start" aria-label="breadcrumb" gap={5}>
        <Link to="/">Home</Link>
        <Type>-</Type>
        <Link to="/products">Products</Link>
      </Flex>
      <Type var="h3" as="h3" sx={{ mt: 18 }}>
        Add Product
      </Type>

      {schemas.length ? (
        <Card sx={{ mt: 40, w: [500, 420, 380, 300] }}>
          {steps.map((step, i) => (
            <Box key={step.label} sx={{mb: 40, mt: 20}}>
              <Type var="h5" as="h5" sx={{ mb: 15 }}>
                {step.label}
              </Type>
              <Box>{step.description}</Box>
            </Box>
          ))}
        </Card>
      ) : (
        status === 'idle' ? <Type sx={{mt: 30, c: 'red', bgc: 'redbg'}}>No Specs found! Go to <Link to="/schemas">Specs</Link>.</Type> : <Loading />
      )}
    </Layout>
  );
}

export default memo(AddProduct);
