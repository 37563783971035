import { useEffect } from "react";
import { Box, Card, Flex, Type } from "ui";
import { useStats } from "views/stats/statsProvider";
import VerificationTransactionTable from "./VerificationTransactionTable";
import { useAuth } from "services/auth";
import Image from "ui/Thumb";

function VerificationTransactionCard({ data }) {
  const { user } = useAuth();

  return (
    <Card>
      <Flex jc="flex-start" gap={20} sx={{ mb: 30 }}>
        {data.image && (
          <Image
            thumb={`${user.user.configs.ipfs_base_url}/${data.thumbnail}`}
            img={`${user.user.configs.ipfs_base_url}/${data.image}`}
            w="80"
            alt={`item ${data.name} photo`}
          />
        )}
        <Box>
          <Type var="h1" as="h1">
            {data.name}
          </Type>
          <Type sx={{mw: 900}}>{data.description}</Type>
          <Type sx={{pt: 10}}>Token ID: {data.nft}</Type>
        </Box>
      </Flex>

      <Type var="h4" sx={{ mb: 20 }}>
        Transactions
      </Type>
      <VerificationTransactionTable data={data.transactions.splice(0, 10)} />
    </Card>
  );
}

export default VerificationTransactionCard;
