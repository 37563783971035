import { useEffect } from "react";

const { Card, Grid, Checkbox, Loading } = require("ui");
const { useUsers } = require("./usersProvider");

const RolesList = ({ onChange, userRolesIds }) => {

  const {
    state: { roles },
    fetchRoles,
  } = useUsers();

  useEffect(() => {
    if (roles.length) return;
    fetchRoles();
  }, [roles, fetchRoles]);

  const handleChange = (e) => {
    const { checked, id } = e.target;

    const newRoles = checked
      ? [...userRolesIds, Number(id)]
      : userRolesIds.filter((r) => r !== Number(id));
    
    onChange(newRoles)
  }

  if (!roles.length) return <Loading />;

  return (
    <Card>
      <Grid cols="1fr 1fr" colGap={0} onChange={handleChange}>
        {roles.map((role) => (
          <Checkbox
            style={{ margin: "10px 0" }}
            value={role.name}
            key={role.name}
            label={role.name}
            id={role.id}
            size="small"
            inputProps={{
              name: "roles",
              defaultChecked: Boolean(
                userRolesIds.includes(role.id)
              ),
            }}
          />
        ))}
      </Grid>
    </Card>
  );
};

export default RolesList;
