import { memo, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, Card, Checkbox, Icon, InputText, Select, Type } from "ui";
import { useFields } from "./fieldProvider";
import { useUtils } from "services/utilsProvider";
import { emptyStringToNull } from "utilities/yupSchemaCreator";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  field_type: Yup.number().nullable().required("Required").transform(emptyStringToNull),
  is_required: Yup.bool(),
});

function FieldForm({ field = null, onSuccess, edit }) {
  const { createField, editField } = useFields();
  const { notify } = useUtils();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: field,
  });

  useEffect(() => {
    reset(field);
  }, [field, reset]);

  const onSubmit = async (values) => {
    try {
      if (edit) await editField(edit, values);
      else await createField(values);
      reset();
      notify(`Field ${edit ? "updated" : "added"} successfully`);
      onSuccess();
    } catch (e) {
      console.log(e);
      notify(e.errors["non_field_errors"].join(", "), "alert");
    }
  };

  const onChange = useCallback(
    (v, name) => {
      setValue(name, v.value);
    },
    [setValue]
  );

  return (
    <Card
      sx={{ ta: "left" }}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Box sx={{ ta: "center", py: 20 }}>
        <Icon name="schema" w={18} />
        <Type var="h6" as="h5" sx={{ mt: 10 }}>
          {edit ? `Editing Specs field` : "Add Spec field"}
        </Type>
      </Box>

      <InputText
        id="name"
        label="Field name"
        error={!!errors.name}
        msg={errors.name?.message}
        inputProps={{
          ...register("name"),
          autoFocus: true,
          required: true,
        }}
      />

      <Select
        style={{ width: "100%", marginBottom: 20 }}
        data={[
          { value: "", label: "Choose type" },
          { value: 1, label: "Number" },
          { value: 2, label: "Text" },
          { value: 3, label: "Yes/No" },
        ]}
        mapData={{ value: "value", label: "label" }}
        name="type"
        label="Field Type"
        search={false}
        noSeperator={true}
        defaultValue={getValues("field_type")}
        onChange={onChange}
        error={!!errors.field_type}
        msg={errors.field_type?.message}
        inputProps={{
          ...register("field_type"),
          required: true,
        }}
      />

      <Checkbox
        id="required"
        label="Is Required?"
        error={!!errors.is_required}
        msg={errors.is_required?.message}
        inputProps={{ ...register("is_required") }}
      />

      <Button
        sx={{ w: "100%", ai: "center", mt: 20 }}
        onClick={handleSubmit}
        loading={isSubmitting}
      >
        {edit ? "Save" : "Create"}
      </Button>
    </Card>
  );
}

export default memo(FieldForm);
