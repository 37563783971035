import ToggleSidebar from "layout/header/ToggleSidebar";
import { Box } from "../../ui";
import Nav from "./Nav";

function Sidebar({setOpen, open, ...props}) {
  return (
    // <Box {...props} style={{position: 'sticky', top: '60px', height: 'calc(100vh - 69px)'}}></Box>
    <Box {...props} relative>
      <Nav isOpen={open} />
      <ToggleSidebar abs={{b: 20, r: 28}} onClick={() => setOpen((v) => !v)} isOpen={open} />
    </Box>
  );
}

export default Sidebar;
