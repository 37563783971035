import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import Users from "./Users";
import UsersList from "./UsersList";
import AddUser from "./AddUser";
// import AddUser from "./AddUser";
// import EditUser from "./EditUser";

export default (
  <Route
    path="users"
    element={
      <RequireAuth>
        <Users />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <UsersList />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <AddUser />
        </RequireAuth>
      }
    />
    {/* <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditUser />
        </RequireAuth>
      }
    /> */}
  </Route>
);
