import { useEffect } from "react";
import { Box, TableContainer } from "ui";
import { useOrders } from "views/orders/orderProvider";

const style = {
  backgroundColor: "transparent",
  color: "#444",
  fontWeight: "600",
  borderBottom: "#ddd 1px solid",
};

const cols = [
  {
    name: "customer_email",
    align: "left",
    disablePadding: false,
    label: "Customer Email",
    props: { style },
  },
  {
    name: "customer_name",
    align: "left",
    disablePadding: true,
    label: "Customer Name",
    props: { style },
  },
  {
    name: "phone_number",
    align: "left",
    disablePadding: false,
    label: "Phone Number",
    props: { style },
  },
  {
    name: "order_details_count",
    align: "left",
    disablePadding: false,
    label: "Order Details Count",
    props: { style },
  },
  {
    name: "created_by",
    align: "left",
    disablePadding: false,
    label: "Created by",
    props: { style },
    render: (row) => (
      <>
        {row.first_name} {row.last_name}
      </>
    ),
  },
];

export default function OrderTable() {
  const {
    state: { list, status },
    fetchList,
  } = useOrders();

  useEffect(() => {
    fetchList(0, 8);
  }, [fetchList]);

  return (
    <Box>
      <TableContainer
        empty={list?.length === 0}
        status={status}
        data={list}
        aria-label="Last orders table"
        cols={cols}
        tableProps={{
          bg: "gray200",
          border: "gray300"
        }}
      />
    </Box>
  );
}
