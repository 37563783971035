import { ThemeProvider } from "styled-components";
import AuthProvider from "./auth";
import UtilsProvider from "./utilsProvider";
import theme from "../theme";
import StatsProvider from "views/stats/statsProvider";
import OrderProvider from "views/orders/orderProvider";
import SchemaProvider from "views/schemas/schemaProvider";
import FieldProvider from "views/fields/fieldProvider";
import ProductProvider from "views/products/productProvider";
import ItemProvider from "views/items/ItemProvider";
import UsersProvider from "views/Users/usersProvider";
import WalletProvider from "views/wallet/WalletProvider";
import TagsProvider from "views/tags/provider";

export default function Providers({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <UtilsProvider>
        <AuthProvider>
          <StatsProvider>
            <OrderProvider>
              <SchemaProvider>
                <FieldProvider>
                  <ProductProvider>
                    <ItemProvider>
                      <UsersProvider>
                        <WalletProvider>
                          <TagsProvider>{children}</TagsProvider>
                        </WalletProvider>
                      </UsersProvider>
                    </ItemProvider>
                  </ProductProvider>
                </FieldProvider>
              </SchemaProvider>
            </OrderProvider>
          </StatsProvider>
        </AuthProvider>
      </UtilsProvider>
    </ThemeProvider>
  );
}
